import { Helmet } from "react-helmet"
import Impressum from "../common/impressum"

export default function ImpressumRoute({loadingFunc}) {
    return (
        <section className="router-body h-full w-full">
            <Helmet>
                <title>WICODE - Impressum</title>
                <link rel='canonical' href='https://wicode.io/impressum' />
                <meta name='description' content='Impressum, Informationen zum Unternehmen' />
            </Helmet>
            <Impressum loadingFunc={loadingFunc}/>
        </section>
    )
}