import React, { useEffect, useState } from "react";
import DrawerButton from "../special/drawerButton";
import NetzBg from "../bg-effects/NetzBg";
import axios from "axios";
import { API } from "../../App";
import { Fade } from "react-reveal";
import dayjs from "dayjs";
import LatestPost from "./components/LatestPost";
import PostSlider from "./components/PostSlider";
import ContactSec from "./components/ContactSec";
import Headlines from "../special/Headlines";

export default function BlogPage({ loadingFunc }) {
    const [blogposts, setblogposts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await axios
                .get(API + "/api/blogposts?populate=*")
                .then((res) => {
                    setblogposts(res?.data?.data?.reverse());
                })
                .catch((err) => console.log(err));

            loadingFunc();
        };

        fetchData();
    }, [loadingFunc]);

    return (
        <main className="min-h-screen h-auto flex flex-col items-center">
            <DrawerButton />
            <section
                id="_"
                className="w-full h-fit overflow-hidden flex flex-col justify-center items-start content-center"
            >
                <Fade left>
                    <h1 className="z-10 text-5xl sm:text-7xl md:text-8xl text-white font-headline uppercase text-start p-12 sm:p-28 md:p-48">
                        Aktuelles & News
                    </h1>
                </Fade>
                <NetzBg />
            </section>
            <section className="bg-base-100 grow w-full flex flex-col items-center">
                <div className="w-full max-w-screen-xl flex flex-col p-8 pt-16 items-center">
                    <Headlines big='Aktueller Beitrag'/>
                    <LatestPost
                        imglink={
                            API +
                            blogposts[0]?.attributes?.img?.data?.attributes?.url
                        }
                        alt={
                            blogposts[0]?.attributes?.img?.data?.attributes
                                ?.name
                        }
                        id={blogposts[0]?.id}
                        title={blogposts[0]?.attributes?.title}
                        content={blogposts[0]?.attributes?.content}
                        dateD={dayjs(
                            blogposts[0]?.attributes?.publishedAt
                        ).format("DD.MM.YYYY")}
                    />
                    <Headlines big='Weitere Beiträge...'/>
                    <PostSlider posts={blogposts} />
                    <ContactSec />
                </div>
            </section>
        </main>
    );
}
