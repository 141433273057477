import { Helmet } from "react-helmet"
import Datenschutz from "../common/datenschutz"

export default function DatenschutzRoute({ loadingFunc }) {
    return (
        <section className="router-body">
            <Helmet>
                <title>WICODE - Datenschutz</title>
                <link rel='canonical' href='https://wicode.io/datenschutz' />
                <meta name='description' content='Datenschutz, Informationen zum Unternehmen' />
            </Helmet>
            <Datenschutz loadingFunc={loadingFunc}/>
        </section>
    )
}