import React, { useCallback } from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';

export default function NetzBg() {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const windowSize = window.innerWidth > 768 ? 1 : 0;


  return (
    <div className='grow min-h-full w-full overflow-hidden absolute -z-30'>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{

          fullScreen: {
            enable: false,
          },
          background: {
            color: {
              value: '#0F0E28',
            },
          },
          // fps_limit: 30,
          // pauseOnOutsideViewport: true,
          fpsLimit: 30,
          interactivity: {
            events: {
              onClick: {
                enable: false,
              },
              onHover: {
                enable: false,
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 1,
              },
                repulse: {
                distance: 100,
                duration: 0.25,
                }
            },
          },
          particles: {
            color: {
              value: '#e5e5e5',
            },
            links: {
              color: '#dcdcdc',
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              directions: 'none',
              bounce: false,
              enable: true,
              outModes: {
                default: 'out',
              },
              random: true,
              speed: windowSize,
              straight: true,
            },
            number: {
              density: {
                enable: true,
                area: 800,
                },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
        style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}
      />
    </div>
  );
}
