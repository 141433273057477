import { Helmet } from "react-helmet";
import { useEffect } from "react";
import FaqPage from "../page_faq/FaqPage";
import axios from "axios";
import { API } from '../../App.js'
import { useState } from "react";

export default function FaqPageRoute ({ loadingFunc }) {

    const [page, setpage] = useState()
    const [faq, setfaq] = useState()

    useEffect(() => {
        window.fbq("track", "Aufruf FAQ");

        const fetchData = async () => {
            await axios    
                .get(API + '/api/seo?populate=*')
                .then(res => setpage(res.data.data.attributes.faq))
                .catch(err => console.log(err))
            await axios
                .get(API + '/api/faqs')
                .then(res => {
                    setfaq(res.data.data)
                    if (res.data.data[0].attributes.section === 3) {
                        console.log(true)
                    } else {
                        console.log(false)
                    }
                })
                .catch(err => console.log(err))
            loadingFunc()
        }
        
        window.fbq('track', 'PageView')
        fetchData()

    }, [loadingFunc]);
    
    return (
        <section className="router-body">
            <Helmet>
                <title>{page?.metaTitle ?? 'Wicode FAQ'}</title>
                <link rel='canonical' href={ page?.canonicalURL ?? 'https://wicode.io/faq'} />
                <meta name='description' content={page?.metaDescription ?? 'Fragen & Antworten über Wicode, Automatisierung und Software'} />
                <meta name='keywords' content={page?.keywords ?? 'Wicode, Automatisierung, Software, Softwareentwicklung, Entwicklung, IT, App, Appentwicklung, Dresden, Leipzig, Zwickau'} />
            </Helmet>
            <FaqPage loadingFunc={loadingFunc} faq={faq}/>
        </section>
    );
}
