import { useEffect, useState } from "react";
// import CallField from "./callfield";
import TextInputField from "../../page_contact/textinputfield";
import axios from "axios";
import { API } from "../../../App";
import MAIL from "../../../res/gifs/mail.gif";

export default function ContactForm() {
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            name: e.target.name.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            message: e.target.subject.value,
            topic: e.target.subject.value,
            budget: values[cbValue],
        };
        setLoading(true);
        await sendContactMail(formData)
            .then((e) => {
                console.log(e);
                console.log(e.data.message);
                window.fbq("track", "K O N T A K T A U F N A H M E");
                if (e.data.code === 200) {
                    setErrorMsg("");
                    setSent(true);
                    setLoading(false);
                } else {
                    setErrorMsg(e.data.message);
                    setSent(false);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setErrorMsg(e.data.message);
                setError(true);
                setSent(false);
                setLoading(false);
            });
    };

    const sendContactMail = async (formData) => {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_MAIL_URL}`,
            data: formData,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Auth": process.env.REACT_APP_API_KEY,
            },
        });
    };

    const [cb1, setCb1] = useState(true);
    const [cb2, setCb2] = useState(false);
    const [cb3, setCb3] = useState(false);
    const [cb4, setCb4] = useState(false);
    const [cb5, setCb5] = useState(false);
    const [cb6, setCb6] = useState(false);
    const [cb7, setCb7] = useState(false);
    const [cbValue, setCbValue] = useState(1);
    const setCheckBox = (cb) => {
        setCbValue(cb);
        if (cb === 1) {
            setCb1(true);
            setCb2(false);
            setCb3(false);
            setCb4(false);
            setCb5(false);
            setCb6(false);
            setCb7(false);
        } else if (cb === 2) {
            setCb1(false);
            setCb2(true);
            setCb3(false);
            setCb4(false);
            setCb5(false);
            setCb6(false);
            setCb7(false);
        } else if (cb === 3) {
            setCb1(false);
            setCb2(false);
            setCb3(true);
            setCb4(false);
            setCb5(false);
            setCb6(false);
            setCb7(false);
        } else if (cb === 4) {
            setCb1(false);
            setCb2(false);
            setCb3(false);
            setCb4(true);
            setCb5(false);
            setCb6(false);
            setCb7(false);
        } else if (cb === 5) {
            setCb1(false);
            setCb2(false);
            setCb3(false);
            setCb4(false);
            setCb5(true);
            setCb6(false);
            setCb7(false);
        } else if (cb === 6) {
            setCb1(false);
            setCb2(false);
            setCb3(false);
            setCb4(false);
            setCb5(false);
            setCb6(true);
            setCb7(false);
        } else if (cb === 7) {
            setCb1(false);
            setCb2(false);
            setCb3(false);
            setCb4(false);
            setCb5(false);
            setCb6(false);
            setCb7(true);
        }
    };

    const values = {
        1: "keine Angabe",
        2: "1.000-5.000€",
        3: "5.000-10.000€",
        4: "10.000-20.000€",
        5: "20.000-30.000€",
        6: "30.000-50.000€",
        7: "50.000-100.000€",
    };

    // API WORK
    const [text, setText] = useState([]);
    useEffect(() => {
        axios
            .get(API + "/api/contact")
            .then((res) => setText(res.data?.data?.attributes))
            .catch((err) => console.log(err));
    }, []);

    return (
        <section className="flex flex-col items-center py-12 sm:py-20 md:py-32 bg-base-100 w-full">
            <h1
                id="_"
                className="text-3xl sm:text-4xl md:text-5xl px-4 text-center font-extrabold pb-2 uppercase"
            >
                {text.headline}
            </h1>
            <h3 className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-center pb-4 px-4">
                {text.text}
            </h3>
            {!sent ? (
                <form
                    autoComplete={"on"}
                    onSubmit={handleSubmit}
                    className="inline-grid grid-cols-1 md:grid-cols-2 w-full p-8 gap-8"
                >
                    <div className="form-control w-full">
                        <TextInputField
                            id="name"
                            text="Vor- & Nachname"
                            placeholder="Max Mustermann"
                            disabled={sent}
                        />
                        <TextInputField
                            id="phone"
                            text="Telefonnummer"
                            padding_top="pt-8"
                            placeholder="+01 234 567890"
                            disabled={sent}
                        />
                    </div>
                    <div className="form-control w-full">
                        <TextInputField
                            id="email"
                            text="E-Mail"
                            placeholder="beispiel@email.com"
                            disabled={sent}
                        />
                        <TextInputField
                            id="subject"
                            text="Thema"
                            padding_top="pt-8"
                            placeholder="Problemgebiet..."
                            disabled={sent}
                        />
                        {/* <div className="border-b-2 border-b-zinc-900 h-80 md:h-full mb-2 w-full">
                            <textarea
                                id="message"
                                className={`textarea bg-base-100 w-full h-full text-xl rounded-none resize-none disabled:bg-base-100`}
                                placeholder="Ich brauche Unterstützung bei..."
                                disabled={sent}
                                required={true}
                            />
                        </div> */}
                    </div>

                    {/* BUdget Check */}
                    <div className="col-start-1 col-end-2 md:col-end-3 w-full flex flex-col lg:flex-row p-8 items-center">
                        <h3 className="text-lg">Budget: </h3>
                        <div className="flex flex-col sm:flex-row">
                            <Check
                                range={values["1"]}
                                checking={cb1}
                                disabled={sent}
                                click={() => setCheckBox(1)}
                            />
                            <Check
                                range={values["2"]}
                                checking={cb2}
                                disabled={sent}
                                click={() => setCheckBox(2)}
                            />
                            <Check
                                range={values["3"]}
                                checking={cb3}
                                disabled={sent}
                                click={() => setCheckBox(3)}
                            />
                            <Check
                                range={values["4"]}
                                checking={cb4}
                                disabled={sent}
                                click={() => setCheckBox(4)}
                            />
                        </div>
                        <div className="flex flex-col sm:flex-row">
                            <Check
                                range={values["5"]}
                                checking={cb5}
                                disabled={sent}
                                click={() => setCheckBox(5)}
                            />
                            <Check
                                range={values["6"]}
                                checking={cb6}
                                disabled={sent}
                                click={() => setCheckBox(6)}
                            />
                            <Check
                                range={values["7"]}
                                checking={cb7}
                                disabled={sent}
                                click={() => setCheckBox(7)}
                            />
                        </div>
                    </div>
                    <div
                        className={
                            "col-start-1 col-end-2 md:col-end-3 px-8 py-3 text-center flex flex-col justify-center w-full "
                        }
                    >
                        <h3 className="md:ml-auto text-lg  md:text-start text-error">
                            {errorMsg}
                        </h3>
                    </div>

                    <div className="flex justify-center md:justify-end w-full col-start-1 col-end-2 md:col-end-3 md:px-32">
                        {!error ? (
                            <button
                                className={`btn btn-primary h-14 px-8 text-xl ${
                                    loading ? "loading" : ""
                                } ${sent ? "hidden" : ""}`}
                                type="submit"
                            >
                                Abschicken
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="pl-1 w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                    />
                                </svg>
                            </button>
                        ) : (
                            <p>
                                Anscheinend ging etwas schief! <br /> Bitte
                                kontaktieren Sie uns über{" "}
                                <span className="text-blue-500 underline">
                                    <a href="mailto:service@wicode.io">
                                        service@wicode.io
                                    </a>
                                </span>
                            </p>
                        )}
                    </div>
                </form>
            ) : (
                <div className="flex flex-col items-center justify-center w-96">
                    <img alt="email" src={MAIL} className="w-40 h-40" />
                    <h4 className="font-semibold text-xl uppercase">
                        Vielen Dank!
                    </h4>
                    <p className="text-center">
                        Wir haben Ihre Nachricht erhalten und werden uns so
                        schnell wie möglich bei Ihnen melden!
                    </p>
                </div>
            )}
        </section>
    );
}

function Check(props) {
    return (
        <div className="form-control mx-1" onClick={props.click}>
            <label className="cursor-pointer label">
                <input
                    type="checkbox"
                    checked={props.checking}
                    className="checkbox checkbox-info"
                    disabled={props.disabled}
                />
                <span className="label-text text-md pl-1">{props.range}</span>
            </label>
        </div>
    );
}
