

export default function TextInputField(props) {
    return(
        <div className={`form-control mb-2 border-b-zinc-900 border-b-2 ${props.padding_top}`}>
            {/* <label className="label pb-0">
                <span className="label-text text-lg italic font-semibold text-accent">{props.text}</span>
            </label> */}
            <input id={props.id} type="text" placeholder={props.placeholder} className={`input border-none text-xl bg-base-100 w-full rounded-none disabled:bg-base-100`} disabled={props.disabled} required={true}/>
        </div>
    )
}
