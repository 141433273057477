import React, { useEffect, useState } from "react";
import Headlines from "../../special/Headlines";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { API } from '../../../App'

export default function ContactSec() {

    const [data, setdata] = useState()

    useEffect(() => {
        const fetchData = async () => {
            await axios
                .get(API + '/api/blog-contact')
                .then(res => setdata(res.data.data.attributes))
                .catch(err => console.log(err))
        }

        fetchData()
    }, [])

    return (
        <div className="w-full flex flex-col p-8 items-center lg:mt-8 lg:mb-12 max-w-screen-lg">
            <Headlines big={data?.headline} />
            <p className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-center pb-4 px-4">
                {data?.description}
            </p>
            <HashLink className="btn btn-lg btn-primary btn-outline" to={'/#contact'} onClick={() => window.fbq("track", "Kontakt auf Blog")}>
                Jetzt Anfragen
            </HashLink>
        </div>
    );
}
