import NetzBg from "../bg-effects/NetzBg";
import { Fade } from "react-reveal";
import { API } from "../../App";
import DrawerButton from "../special/drawerButton";
import ReactMarkdown from 'react-markdown';
import { customComponents } from "../common/MarkdownComponents";
import ContactSec from "./components/ContactSec";

export default function BlogPostPage({ blog }) {

    return (
        <main className='w-full min-h-screen h-auto flex flex-col items-center'>
            <DrawerButton />
            <section
                id="_"
                className="w-full h-fit overflow-hidden flex flex-col justify-center items-start content-center"
            >
                <Fade left>
                    <h3 className="z-10 text-3xl sm:text-4xl md:text-5xl text-white font-headline uppercase text-start py-16 px-24 sm:px-32 md:px-48">
                        Aktuelles & News
                    </h3>
                </Fade>
                <NetzBg />
            </section>
            <section className="w-full flex flex-col items-center bg-base-100 grow px-4 sm:px-8">
                <div className="flex flex-col items-center w-full max-w-screen-lg pt-12">
                    <img src={API + blog?.img?.data?.attributes?.url} alt={blog?.img?.data?.attributes?.name} className="max-h-[24rem] max-w-full grow rounded-md" />
                    <h1 className="text-3xl font-bold font-text-headline uppercase underline pt-8 text-center">{blog?.title}</h1>
                    <div className="text-base columns-1 md:columns-2 pt-8 pb-16">
                        <ReactMarkdown components={customComponents}>
                            {blog?.content}
                        </ReactMarkdown>
                    </div>
                </div>
            </section>
            <ContactSec />
        </main>
    )
}
