import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DrawerButton from "../special/drawerButton";
import Fade from "react-reveal/Fade";
import axios from "axios";
import ContactForm from "./components/contactform";
import { HashLink } from "react-router-hash-link";
import { API } from "../../App";
import { motion } from "framer-motion";
import gsap, { ScrollTrigger } from "gsap/all";
import IMG from "../../res/fotos/wicode-removebg-preview.png";
import IMG1 from "../../res/fotos/analysis.jpg";
import IMG2 from "../../res/fotos/implement.jpg";
import SUCCESS from "../../res/gifs/wired-outline-648-victory-success.gif";
import ERROR from "../../res/gifs/wired-outline-1140-error.gif";
import LogoSlider from "./technologies/LogoSlider";
import Headlines from "../special/Headlines";
import LogoSection from "./technologies/LogoSection";
import MethodGridElement from "./components/MethodGridElement";
import ScrollingElement from "./components/ScrollingElement";
import GridElement from "./components/GridElement";
import CusotmerSlider from "./components/CusotmerSlider";
import LogoGrid from "./technologies/LogoGrid";

gsap.registerPlugin(ScrollTrigger);

export default function Homepage({ loadingFunc }) {
    const [landing, setLanding] = useState([]);
    const [testimonials, settestimonials] = useState([]);
    const [logos, setLogos] = useState([]);
    const [logoSlider, setlogoSlider] = useState([]);
    const [methode, setMethode] = useState([]);
    const [painpoints, setPainpoints] = useState([]);
    const [solutions, setSolutions] = useState([]);
    const [request, setRequest] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            // LANDING
            await axios
                .get(API + "/api/landing?populate=*")
                .then((res) => setLanding(res.data?.data?.attributes))
                .catch((err) => console.log("Error: ", err));
            // METHODE
            await axios
                .get(API + "/api/methode")
                .then((res) => setMethode(res?.data?.data?.attributes))
                .catch((err) => console.log(err));
            // PAINTPOINTS
            await axios
                .get(API + "/api/painpoints")
                .then((res) => setPainpoints(res?.data?.data))
                .catch((err) => console.log(err));
            // SOLUTIONS
            await axios
                .get(API + "/api/solutions")
                .then((res) => setSolutions(res?.data?.data))
                .catch((err) => console.log(err));
            // FOR WHO
            await axios
                .get(API + "/api/testimonials?populate=*")
                .then((res) => settestimonials(res?.data.data))
                .catch((err) => console.log(err));
            // REQUESTS
            await axios
                .get(API + "/api/request")
                .then((res) => setRequest(res?.data?.data?.attributes))
                .catch((err) => console.log(err));
            // LOGO
            await axios
                .get(API + "/api/logos?populate=*")
                .then((res) => {
                    setLogos(res?.data?.data);
                    setlogoSlider(res?.data?.data);
                })
                // .then((res) => console.log('Logo: ' ,res?.data?.data))
                .catch((err) => console.log(err));

            loadingFunc();
        };

        fetchData();
    }, [loadingFunc]);

    const container1 = useRef();
    const container2 = useRef();
    const container3 = useRef();
    const container4 = useRef();
    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            ScrollTrigger.create({
                trigger: container1.current,
                endTrigger: container2.current,
                pin: true,
                start: "top top",
                end: "top center",
                pinSpacing: false,
            });
        });
        return () => ctx.revert();
    });
    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            ScrollTrigger.create({
                trigger: container3.current,
                endTrigger: container4.current,
                pin: true,
                start: "top top",
                end: "top center",
                pinSpacing: false,
            });
        });
        return () => ctx.revert();
    });
    const scroll1 = useRef();
    const scroll2 = useRef();
    const scroll3 = useRef();
    const [isVisible1, setIsVisible1] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [isVisible3, setIsVisible3] = useState(false);
    const [scrollTriggerBlocker, setScrollTriggerBlocker] = useState(false);

    const handleScrollTriggerBlock = () => {
        setScrollTriggerBlocker(true);
        setTimeout(() => {
            setScrollTriggerBlocker(false);
        }, 750);
    };

    useLayoutEffect(() => {
        if (!scrollTriggerBlocker) {
            const trigger1 = ScrollTrigger.create({
                trigger: scroll1.current,
                start: "top bottom",
                onEnter: () => setIsVisible1(true),
            });
            return () => {
                trigger1.kill();
            };
        }
    });

    useLayoutEffect(() => {
        if (!scrollTriggerBlocker) {
            const trigger2 = ScrollTrigger.create({
                trigger: scroll2.current,
                start: "top bottom",
                onEnter: () => setIsVisible2(true),
            });
            return () => {
                trigger2.kill();
            };
        }
    });

    useLayoutEffect(() => {
        if (!scrollTriggerBlocker) {
            const trigger3 = ScrollTrigger.create({
                trigger: scroll3.current,
                start: "top bottom",
                onEnter: () => setIsVisible3(true),
            });
            return () => {
                trigger3.kill();
            };
        }
    });

    const [breakpoint, setBreakpoint] = useState(false);
    useLayoutEffect(() => {
        if (!scrollTriggerBlocker) {
            const trigger4 = ScrollTrigger.create({
                trigger: container1.current,
                start: "top top",
                onEnter: () => setBreakpoint(true),
            });
            return () => {
                trigger4.kill();
            };
        }
    });

    const [respoValue, setRespoValue] = useState(window.innerWidth);

    
    return (
        <main className="min-h-screen flex flex-col items-center">
            <DrawerButton />
            <motion.img
                initial={{
                    x: -800,
                    rotate: 360,
                }}
                animate={{
                    scale:
                        respoValue >= 1536
                            ? 1.5
                            : respoValue >= 1280
                            ? 1.2
                            : 1.0,
                    x: breakpoint ? 0 : -800,
                    rotate: breakpoint ? 0 : 360,
                }}
                transition={{
                    type: "ease",
                    duration: 3,
                }}
                src={IMG}
                alt="kugel"
                className="fixed -left-64 xl:-left-40 2xl:left-0 z-0 scale-100 xl:scale-110 2xl:scale-150 opacity-20 xl:opacity-20"
                style={{ pointerEvents: "none" }}
            />
            {/* SECTION - LANDING */}
            <section
                id="_"
                className="relative w-screen sm:min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-[#0F0E18] to-[#0F0E28]"
            >
                <div className="text-6xl pt-14 sm:pt-0 sm:text-7xl md:text-9xl sm:min-h-screen drop-shadow-lg flex flex-col justify-center items-center w-full max-w-5xl z-10 px-8">
                    <motion.h1
                        initial={{
                            x: "300%",
                            opacity: 0,
                        }}
                        animate={{
                            x: 0,
                            opacity: 1,
                        }}
                        transition={{
                            duration: 2,
                            delay: 1.5,
                            type: "ease",
                        }}
                        className="font-bold font-headline uppercase text-primary z-10"
                    >
                        Effizienz
                    </motion.h1>
                    <motion.h1
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                        }}
                        transition={{
                            duration: 1,
                            delay: 3.5,
                            type: "ease",
                        }}
                        className="font-bold font-headline uppercase text-gray-500 text-5xl sm:text-6xl md:text-7xl"
                    >
                        durch
                    </motion.h1>
                    <motion.h1
                        initial={{
                            x: "-300%",
                        }}
                        animate={{
                            x: 0,
                        }}
                        transition={{
                            duration: 2,
                            delay: 2.5,
                            type: "ease",
                        }}
                        className="font-bold font-headline uppercase text-gray-300"
                    >
                        Software
                    </motion.h1>
                    {/* SAVINGS */}
                    <div className="pt-2 pb-8 md:py-16 md:max-w-5xl flex flex-wrap sm:flex-row justify-center items-center sm:justify-between w-full text-2xl">
                        <motion.div
                            initial={{
                                y: -3500,
                            }}
                            animate={{
                                y: 0,
                            }}
                            transition={{
                                duration: 1.5,
                                delay: 3.5,
                                type: "ease",
                            }}
                            className="flex flex-col text-center sm:text-right w-32 lg:w-64"
                        >
                            <p className="text-sm sm:text-lg md:text-xl text-gray-400">
                                über
                            </p>
                            <p className="text-2xl sm:text-4xl md:text-5xl text-gray-200 pt-1 leading-8 font-text-headline font-bold">
                                {landing?.cash}
                            </p>
                            <p className="text-sm sm:text-lg md:text-xl text-gray-400">
                                Euro gespart
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{
                                y: 2500,
                            }}
                            animate={{
                                y: 0,
                            }}
                            transition={{
                                duration: 1.5,
                                delay: 3.5,
                                type: "ease",
                            }}
                            className="flex flex-col text-center w-32 lg:w-64 py-4 sm:py-0"
                        >
                            <p className="text-sm sm:text-lg md:text-xl text-gray-400">
                                über
                            </p>
                            <p className="text-2xl sm:text-4xl md:text-5xl text-gray-200 pt-1 leading-8 font-text-headline font-bold">
                                {landing?.hours}
                            </p>
                            <p className="text-sm sm:text-lg md:text-xl text-gray-400">
                                Stunden gespart
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{
                                x: "-1000%",
                            }}
                            animate={{
                                x: 0,
                            }}
                            transition={{
                                duration: 1.5,
                                delay: 3.5,
                                type: "ease",
                            }}
                            className="flex flex-col text-center sm:text-left w-32 lg:w-64"
                        >
                            <p className="text-sm sm:text-lg md:text-xl text-gray-400">
                                über
                            </p>
                            <p className="text-2xl sm:text-4xl md:text-5xl text-gray-200 pt-1 leading-8 font-text-headline font-bold">
                                {landing?.nervs}
                            </p>
                            <p className="text-sm sm:text-lg md:text-xl text-gray-400">
                                Nerven gespart
                            </p>
                        </motion.div>
                    </div>
                    <motion.p
                        initial={{
                            x: "1000%",
                        }}
                        animate={{
                            x: 0,
                        }}
                        transition={{
                            duration: 2,
                            delay: 3.25,
                            type: "ease",
                        }}
                        className="text-center max-w-4xl text-base sm:text-lg md:text-xl text-base-100"
                    >
                        {landing?.text}
                    </motion.p>
                    <motion.div
                        initial={{
                            y: "200%",
                            opacity: 0,
                        }}
                        animate={{
                            y: 0,
                            opacity: 1,
                        }}
                        transition={{
                            duration: 1,
                            delay: 5,
                            type: "ease",
                        }}
                    >
                        <HashLink
                            onClick={() => {
                                handleScrollTriggerBlock();
                                window.fbq("track", "HeroButton_Homepage", {
                                    eventName: "buttonClick",
                                });
                            }}
                            smooth={true}
                            to={ (respoValue >= 640) ?  "#contact" : '/kontakt#_'}
                            className="btn btn-primary btn-outline btn-md md:btn-lg w-fit px-8 py-4 h-fit"
                        >
                            Unverbindliche{" "}
                            <span className="px-3">Prozessanalyse</span>{" "}
                            anfordern
                        </HashLink>
                    </motion.div>
                </div>
                {/* LOGOS IN BG  */}
                <LogoSection logos={logos} setLogos={setLogos} />
            </section>
            {/* PAIN POINTS */}
            <section className="w-full h-fit bg-gradient-to-b from-[#0F0E28] to-accent flex flex-col md:flex-row items-center justify-center px-8 py-12 sm:py-20 md:py-0">
                <div className="hidden md:flex w-full max-w-screen-xl">
                    <div className="h-screen py-16" />
                    <div
                        ref={container1}
                        className="h-screen w-1/2 flex flex-col items-center pt-16"
                    >
                        <Headlines
                            big="Probleme unserer Kunden"
                            small="Digitalisierung - ein Fremdwort"
                            color
                        />
                        <img
                            src={ERROR}
                            alt="error gif"
                            className="max-h-[12rem]"
                        />
                        <HashLink
                            onClick={() => {
                                handleScrollTriggerBlock();
                                window.fbq(
                                    "track",
                                    "CustomerProblemButton_Homepage",
                                    { eventName: "buttonClick" }
                                );
                            }}
                            smooth={true}
                            to={ (respoValue >= 640) ?  "#contact" : '/kontakt#_'}
                            className="btn btn-primary btn-lg btn-outline w-64"
                        >
                            Unverbindlich anfragen
                        </HashLink>
                    </div>
                    <div className="w-1/2 h-fit flex items-center justify-center flex-col p-16">
                        {painpoints?.map((item, index) => (
                            <ScrollingElement
                                key={index}
                                bool={true}
                                headline={item?.attributes?.headline}
                                text={item?.attributes?.text}
                                color="b"
                            />
                        ))}
                    </div>
                </div>
                <div className="h-fit w-full flex md:hidden flex-col items-center">
                    <Headlines
                        big="Probleme unserer Kunden"
                        small="Digitalisierung - ein Fremdwort"
                        color
                    />
                    <HashLink
                        onClick={() => {
                            handleScrollTriggerBlock();
                            window.fbq(
                                "track",
                                "CustomerSolutionsButton_Homepage",
                                { eventName: "buttonClick" }
                            );
                        }}
                        smooth={true}
                        to={ (respoValue >= 640) ?  "#contact" : '/kontakt#_'}
                        className="btn btn-primary btn-outline w-64"
                    >
                        Unverbindlich anfragen
                    </HashLink>
                    <img
                        src={ERROR}
                        alt="error gif"
                        className="max-h-[12rem]"
                    />
                </div>
                <div className="inline-grid md:hidden grid-cols-1 sm:grid-cols-2 gap-4">
                    {painpoints?.map((item) => (
                        <ScrollingElement
                            bool={true}
                            headline={item?.attributes?.headline}
                            text={item?.attributes?.text}
                            color="b"
                        />
                    ))}
                </div>
            </section>
            {/* METHODE */}
            <section
                ref={container2}
                id="third"
                className="w-full z-10 bg-base-100 flex flex-col justify-center items-center py-12 sm:py-20 md:py-32"
            >
                <Headlines
                    big={methode.headline}
                    small={methode.smallheadline}
                />
                <div className="inline-grid grid-flow-col-dense max-w-screen-xl gap-6 py-8 overflow-x-scroll md:overflow-hidden w-full md:w-fit px-8">
                    <Fade left>
                        <MethodGridElement
                            img={IMG1}
                            headline="1. Schritt"
                            text={methode.card1}
                        />
                    </Fade>
                    <Fade bottom>
                        <MethodGridElement
                            img={IMG}
                            headline="2. Schritt"
                            text={methode.card2}
                        />
                    </Fade>
                    <Fade right>
                        <MethodGridElement
                            img={IMG2}
                            headline="3. Schritt"
                            text={methode.card3}
                        />
                    </Fade>
                </div>
            </section>
            {/* SOLUTIONS */}
            <section className="w-full h-fit bg-gradient-to-tl from-base-200 to-accent flex flex-col md:flex-row items-center justify-center px-8 py-12 sm:py-20 md:py-0">
                <div className="hidden md:flex w-full max-w-screen-xl">
                    <div className="w-1/2 h-fit flex items-center justify-center flex-col p-16">
                        {solutions.map((item) => (
                            <ScrollingElement
                                bool={true}
                                headline={item?.attributes?.headline}
                                text={item?.attributes?.text}
                                color="b"
                            />
                        ))}
                    </div>
                    <div className="h-screen py-16" />
                    <div
                        ref={container3}
                        className="h-screen w-1/2 flex flex-col items-center pt-16"
                    >
                        <Headlines
                            color
                            big="Unsere Kunden nach der Zusammenarbeit"
                            small="Digitalisierung (er)leben"
                        />
                        <img
                            src={SUCCESS}
                            alt="success gif"
                            className="max-h-[12rem]"
                        />
                        <HashLink
                            onClick={() => {
                                handleScrollTriggerBlock();
                                window.fbq(
                                    "track",
                                    "CustomerSolutionButton_Homepage",
                                    { eventName: "buttonClick" }
                                );
                            }}
                            smooth={true}
                            to={ (respoValue >= 640) ?  "#contact" : '/kontakt#_'}
                            className="btn btn-primary btn-lg btn-outline w-64"
                        >
                            Unverbindlich anfragen
                        </HashLink>
                    </div>
                </div>
                <div className="h-fit w-full flex md:hidden flex-col items-center">
                    <h4 className="font-text-headline text-center text-xl sm:text-2xl tracking-widest font-semibold text-primary">
                        Digitalisierung (er)leben
                    </h4>
                    <h3 className="text-3xl sm:text-4xl md:text-5xl font-headline font-bold uppercase text-base-100 drop-shadow-lg text-center pb-2">
                        Unsere Kunden nach der Zusammenarbeit
                    </h3>
                    <HashLink
                        onClick={handleScrollTriggerBlock}
                        smooth={true}
                        to={ (respoValue >= 640) ?  "#contact" : '/kontakt#_'}
                        className="btn btn-primary btn-outline w-64"
                    >
                        Unverbindlich anfragen
                    </HashLink>
                    <img
                        src={SUCCESS}
                        alt="success gif"
                        className="max-h-[12rem]"
                    />
                </div>
                <div className="inline-grid md:hidden grid-cols-1 sm:grid-cols-2 gap-4">
                    {solutions.map((item) => (
                        <ScrollingElement
                            bool={true}
                            headline={item?.attributes?.headline}
                            text={item?.attributes?.text}
                            color="b"
                        />
                    ))}
                </div>
            </section>
            {/* CUSTOMERS */}
            <section
                ref={container4}
                className="h-fit w-full py-12 sm:py-20 md:py-32 flex flex-col items-center bg-base-100 z-10 relative"
            >
                <Headlines
                    big="Fragen Sie Unsere Kunden"
                    small="Vertrauen Bewiesen"
                />
                {/* <LogoSlider logos={logoSlider} /> */}
                <LogoGrid logos={logoSlider} />
                {/* TESTIMONIALS */}
                <CusotmerSlider testimonials={testimonials} bg={API + landing?.img?.data?.attributes?.url}/>
            </section>
            {/* CTA */}
            <section className="w-full flex flex-col justify-center items-center pt-12 sm:pt-20 md:pt-32 bg-gradient-to-tl from-base-200 to-accent">
                <h3 className="text-3xl sm:text-4xl md:text-5xl font-headline font-bold uppercase text-base-100 drop-shadow-lg px-8 text-center">
                    {request?.headline}
                </h3>
                <p className="max-w-screen-lg text-base-100/80 text-center pt-2 px-8">
                    {request?.description}
                </p>
                <div className="inline-grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-screen-xl w-fit gap-4 py-8 h-fit px-4 sm:px-8">
                    <Fade left>
                        <GridElement color="b" count="1." text={request?.card1} />
                    </Fade>
                    <Fade bottom>
                        <GridElement color="b" count="2." text={request.card2} />
                    </Fade>
                    <Fade right>
                        <GridElement
                            color="b"
                            count="3."
                            text={request.card3}
                            class="md:col-start-1 md:col-end-3 lg:col-auto col-auto"
                        />
                    </Fade>
                </div>
                {/* <HashLink
                    onClick={handleScrollTriggerBlock}
                    smooth={true}
                    to="#contact"
                    className="btn btn-primary btn-outline w-64"
                >
                    Jetzt Termin vereinbaren
                </HashLink> */}
            </section>
            <div id="contact" className="w-full z-10">
                <ContactForm />
            </div>
        </main>
    );
}
