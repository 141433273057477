import { useState, useEffect, useLayoutEffect, useRef } from "react";

import DrawerButton from "../special/drawerButton";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Pulse from "react-reveal/Pulse";
import axios from "axios";
import ExtraElements from "../special/ExtraElements";

import { HashLink } from "react-router-hash-link";
import { API } from "../../App";
import gifPhoto from "../../res/gifs/photo.gif";
import gifCam from "../../res/gifs/cam.gif";
import gifDoc from "../../res/gifs/doc.gif";
import gifTool from "../../res/gifs/tool.gif";
import gifGlobe from "../../res/gifs/globe.gif";
import NetzBg from "../bg-effects/NetzBg";

import IMG1 from "../../res/fotos/new/automation/portrait-busy-hard-working-astonished-businessman-with-office-folders-laptop-talking-phone-office-worker-career-misunderstanding-smart-handsome-man.jpg";
import IMG2 from "../../res/fotos/new/automation/2629.jpg";
import Hyphenated from "react-hyphen";
import de from "hyphenated-de";
import Headlines from "../special/Headlines";
import gsap, { ScrollTrigger } from "gsap/all";
import ContactForm from "../page_home/components/contactform";

export default function AutomationPage({ loadingFunc }) {
    const [landing, setLanding] = useState([]);
    const [truth, setTruth] = useState([]);
    const [methode2, setMethode2] = useState([]);
    const [nobrainer, setNobrainer] = useState([]);
    const [bonus, setBonus] = useState([]);
    const [bonusElement, setBonusElement] = useState([]);

    // API WORK
    useEffect(() => {
        const fetchData = async () => {
            // LANDING
            await axios
                .get(API + "/api/automatisierung-start")
                .then((res) => setLanding(res.data?.data?.attributes))
                .catch((err) => console.log(err));
    
            // TRUTH
            await axios
                .get(API + '/api/truth')
                .then(res => setTruth(res?.data?.data?.attributes))
                .catch(err => console.log(err))
    
            // METHODE2
            await axios
                .get(API + "/api/methode2")
                .then(res => setMethode2(res.data?.data?.attributes))
                .catch(err => console.log(err));
    
            // NOBRAINER
            await axios
                .get(API + '/api/nobrainer')
                .then(res => setNobrainer(res?.data?.data?.attributes))
                .catch(err => console.log(err))
    
            // BONUS
            await axios
                .get(API + "/api/automatisierung-bonus")
                .then((res) => setBonus(res.data?.data?.attributes))
                .catch((err) => console.log(err));
    
            // BONUS ELEMENTS
            await axios
                .get(API + "/api/bonuses")
                .then((res) => setBonusElement(res.data?.data))
                .catch((err) => console.log(err));

            loadingFunc();
            
        }

        fetchData()
    }, [loadingFunc]);

    const main = useRef();
 
    useLayoutEffect(() => {

        if (window.innerWidth > 1024) {
            const ctx = gsap.context((self) => {                        // <-- creates the context for the animation
                gsap.utils.toArray(".panel").forEach((panel, i) => {    // <-- sets the ScrollTrigger-Animation which gets defined after to every element with id=panel
                    ScrollTrigger.create({
                        trigger: panel,
                        start: "top top",
                        pin: true,
                        pinSpacing: false
                    })
                })
            }, main);

            return () => ctx.revert()
        }

        
    })

    const [respoValue, setRespoValue] = useState(window.innerWidth);

    return (
        <main ref={main} className="min-h-screen h-auto flex flex-col items-center">
            <DrawerButton />
            {/* SECTION - LANDING */}
            <section id="_" className="panel w-full h-[48rem] overflow-hidden flex flex-col justify-center items-center content-center">
                <div className="max-w-screen-xl px-4 sm:px-16 xl:px-0 w-full h-fit self-center">
                    <div className="w-fit flex flex-col max-w-full xl:max-w-4xl 2xl:max-w-3xl  items-end">
                        <Fade bottom>
                            <p className="text-primary font-text-headline font-bold text-lg sm:text-2xl tracking-widest uppercase text-end">
                                {landing?.primary}
                            </p>
                        </Fade>
                        <Fade left big>
                            <h1 className="z-10 text-5xl sm:text-7xl md:text-8xl text-white font-headline uppercase text-end">
                                {landing?.headline1}
                            </h1>
                        </Fade>
                        <Fade right big>
                            <h1 className="z-10 hidden lg:block text-5xl sm:text-7xl md:text-8xl text-white font-headline uppercase text-end">
                                {landing?.headline2}                                
                            </h1>
                        </Fade>
                        <Fade right big>
                            <h1 className="z-10 lg:hidden text-5xl sm:text-7xl md:text-8xl text-white font-headline uppercase text-end">
                                <Hyphenated lang={de}>
                                    {landing?.headline2}
                                </Hyphenated>
                            </h1>
                        </Fade>
                        <Fade top>
                            <p className="text-white text-end w-full sm:w-4/5 md:w-full max-w-xl text-sm sm:text-base">
                                {landing.text}
                            </p>
                        </Fade>
                    </div>
                </div>
                <NetzBg />
                {/* <AnimationBgPerformance /> */}
            </section>
            {/* PROBLEM SECTION */}
            <section id="probleme" className="panel relative w-full bg-base-100 sm:py-20 py-32 to-accent flex flex-col items-center justify-center overflow-hidden">
                <div className="max-w-screen-xl flex flex-col items-center justify-center h-fit z-10">
                    <div className="text-7xl md:text-9xl font-bold text-primary flex w-28 md:w-48 h-20 md:h-32 relative">
                        <svg
                            className="absolute left-0"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            height="1.1em"
                            width="1.1em"
                        >
                            <path d="M7.005 3.1a1 1 0 111.99 0l-.388 6.35a.61.61 0 01-1.214 0L7.005 3.1zM7 12a1 1 0 112 0 1 1 0 01-2 0z" />
                        </svg>
                        <svg
                            className="absolute right-0"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            height="1em"
                            width="1em"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 01-.5.5h-.77a.5.5 0 01-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777zM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14z"
                            />
                        </svg>
                    </div>
                    <Headlines big="verdammt" />
                    <p className="text-xl sm:text-2xl md:text-3xl font-text-headline font-semibold text-center">
                        ich habe keine Zeit mehr!
                    </p>
                </div>
                <div className="hidden w-full max-w-screen-xl mt-16 h-96 md:flex flex-row justify-between pl-24">
                    <div className="flex z-10 gap-4 w-112 relative">
                        <Fade left>
                            <div className="absolute rounded-2xl text-sm font-semibold text-justify bg-white shadow-2xl h-fit p-4 w-40">
                                <Hyphenated language={de}>
                                    jeden Tag geht Zeit für unnötige & repetitive
                                    Aufgaben drauf
                                </Hyphenated>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className=" absolute left-[10.5rem] tracking-tight -top-6 rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-40">
                                <Hyphenated language={de}>
                                    kaum noch Zeit sich auf das eigentlich Geschäft
                                    zu konzentrieren
                                </Hyphenated>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="absolute right-16 top-24 tracking-tight rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-48">
                                <Hyphenated language={de}>
                                    Mitarbeiter haben keine Zeit mehr für ihre
                                    eigentlichen Aufgaben
                                </Hyphenated>
                            </div>
                        </Fade>
                        <Fade left>
                            <div className="absolute -left-2 top-[7.25rem] tracking-tight rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-48">
                                <Hyphenated language={de}>
                                    Ihr Unternehmen entwickelt sich nicht weiter
                                </Hyphenated>
                            </div>
                        </Fade>
                        <Fade bottom>

                            <div className="absolute right-44 bottom-20 tracking-tight rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-40">
                                <Hyphenated language={de}>
                                    Zunehmend unmotivierte Mitarbeiter wegen
                                    unnötiger Aufgaben
                                </Hyphenated>
                            </div>
                        </Fade>
                        <Fade>

                            <div className="absolute -right-6 bottom-24 tracking-tight rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-48">
                                <Hyphenated language={de}>
                                    sucht ständig in den Jobbörsen nach neuen
                                    Mitarbeitern
                                </Hyphenated>
                            </div>
                        </Fade>
                        <Fade top>

                        <div className=" absolute -top-24 -left-4 rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-40">
                            <Hyphenated language={de}>
                                zu viel MS-Office (Excel-, Word-, PDF-Chaos)
                            </Hyphenated>
                        </div>
                        </Fade>
                        <Fade right>

                        <div className="absolute -right-28 bottom-48 rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-40">
                            <Hyphenated language={de}>
                                tote Dokumente
                            </Hyphenated>
                        </div>
                        </Fade>
                        <Fade bottom>

                        <div className=" absolute -right-10 bottom-3 rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-48">
                            <Hyphenated language={de}>
                                keine Struktur, kein System, kein Überblick
                            </Hyphenated>
                        </div>
                        </Fade>
                        <Fade top>

                        <div className="absolute -right-56 top-52 rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-48">
                            <Hyphenated language={de}>
                                wiederkehrende, monotone Aufgaben
                            </Hyphenated>
                        </div>
                        </Fade>
                        <Fade right>
                        <div className="absolute -right-[18.75rem] bottom-10 rounded-2xl text-sm font-semibold text-justify bg-white shadow-xl h-fit p-4 w-fit">
                            <Hyphenated language={de}>
                                jeden Tag neue Berge an E-Mails
                            </Hyphenated>
                        </div>
                        </Fade>
                    </div>
                </div>
                <img
                    src={IMG1}
                    className="absolute opacity-25 sm:opacity-40 w-full sm:w-2/3 md:w-3/5 lg:w-1/2 h-[64rem] object-cover top-0 sm:-right-12"
                    alt="no time people"
                />
                <div className="sm:w-1/2 h-[64rem] absolute top-0 sm:right-32 md:right-48 lg:right-64 xl:right-80 bg-gradient-to-r from-base-100 via-base-100 " />
            </section>
            {/* DIE WAHRHEIT IST... */}
            <section id="the truth" className="panel relative w-full bg-gradient-to-tl from-base-200 to-accent text-base-100 flex flex-col items-center py-12 sm:py-20 md:py-32 px-8 justify-center overflow-hidden">
                <div className="max-w-screen-xl flex items-center justify-center flex-col">
                    <Headlines
                        small={truth.smallheadline}
                        big={truth.headline}
                    />
                    <p className="text-base sm:text-xl md:text-2xl max-w-3xl text-center">
                       {truth.text}
                    </p>
                </div>
                <div className="w-full flex flex-row flex-wrap max-w-screen-xl gap-x-3 gap-y-6 sm:gap-0 md:gap-5 justify-center pt-8">
                    <Fade left>

                    <TheTruth
                        up="und plötzlich"
                        center={
                            <p className="leading-5 md:leading-7">
                                {" "}
                                mehr <br /> zeit
                            </p>
                        }
                        down="für's Tagesgeschäft"
                        class=""
                    />
                    </Fade>
                    <Fade bottom>

                    <TheTruth
                        up="das bedeutet"
                        center={
                            <p className="leading-5 md:leading-7">
                                {" "}
                                Mehr <br /> Neukunden
                            </p>
                        }
                        down="für Ihr Unternehmen"
                    />
                    </Fade>
                    <Fade top>
                        <TheTruth
                            up="das bringt"
                            center={
                                <p className="leading-5 md:leading-7">
                                    {" "}
                                    mehr <br /> umsatz
                                </p>
                            }
                            down="für Ihr Unternehmen"
                        />
                    </Fade>
                    <Fade right>
                        <TheTruth
                            up="darauf folgt"
                            center={
                                <p className="leading-5 md:leading-7">
                                    {" "}
                                    mehr <br /> Bewerber
                                </p>
                            }
                            down="für offene Stellen"
                        />
                    </Fade>
                </div>
            </section>
            {/* METHODE */}
            <section id="methode" className="panel relative w-full bg-base-100 py-12 sm:py-20 md:py-32 to-accent flex flex-col items-center justify-center overflow-hidden">
                <img
                    src={IMG2}
                    alt="stairs img"
                    className="absolute w-full h-full opacity-40"
                />
                <div className="flex flex-col max-w-screen-2xl w-full z-10 px-4 text-sm sm:text-base">
                    <Slide left>
                        <div className="flex flex-col items-center md:items-start h-fit w-full md:pl-12">
                            <div className="w-fit">

                                <p className="text-primary font-headline font-bold uppercase text-start text-2xl md:text-4xl ">
                                    <div dangerouslySetInnerHTML={{ __html: methode2.headline_step1 }} />
                                </p>
                                <p className="sm:pl-4 md:pl-8 max-w-lg font-semibold">
                                    <div dangerouslySetInnerHTML={{ __html: methode2.text_step1 }} />
                                </p>
                            </div>
                        </div>
                    </Slide>
                    <Slide left>
                        <div className="flex flex-col items-center h-fit w-full py-8 xl:py-0">
                            <div className="w-fit max-w-lg">
                                <p className="text-primary font-headline font-bold uppercase text-start text-2xl md:text-4xl ">
                                    
                                    <div dangerouslySetInnerHTML={{ __html: methode2.headline_step2 }} />
                                </p>
                                <p className="sm:pl-4 md:pl-8 max-w-lg font-semibold">
                                    <div dangerouslySetInnerHTML={{ __html: methode2.text_step2 }} />
                                </p>
                            </div>
                        </div>
                    </Slide>
                    <Slide left>
                        <div className="flex flex-col items-center md:items-end h-fit w-full md:pr-12">
                            <div className="w-fit max-w-lg">
                                <p className="text-primary font-headline font-bold uppercase text-start text-2xl md:text-4xl ">
                                    <div dangerouslySetInnerHTML={{ __html: methode2.headline_step3 }} />
                                </p>
                                <p className="sm:pl-4 md:pl-8 max-w-lg font-semibold">
                                    <div dangerouslySetInnerHTML={{ __html: methode2.text_step3 }} />
                                </p>
                            </div>
                        </div>
                    </Slide>
                </div>
            </section>
            {/* NO BRAINER */}
            <section id="no-brainer" className=" panel w-full bg-gradient-to-tl from-base-200 to-accent text-base-100 flex py-12 sm:py-20 md:py-32 px-8 justify-center">
                <div className="max-w-screen-xl flex items-center justify-center flex-col">
                    <Headlines
                        big={nobrainer.headline}
                    />
                    <Slide left>
                        <div className="text-start max-w-5xl pt-4 flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-12 w-12 sm:h-16 sm:w-16 lg:w-20 lg:h-20 text-primary"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            <p className="pl-4 w-fit text-sm sm:text-base">
                                {nobrainer.bad}
                            </p>
                        </div>
                    </Slide>
                    <div className="border-b border-base-100 my-4 w-[90%]" />
                    <Slide right>
                        <div className="text-start max-w-5xl flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="h-12 w-12 sm:h-16 sm:w-16 lg:w-20 lg:h-20 text-primary"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <p className="pl-4 w-fit text-sm sm:text-base">
                                {nobrainer.good}
                            </p>
                        </div>
                    </Slide>
                    <Pulse>
                        <p className="font-semibold text-center uppercase tracking-wider font-text-headline text-xl w-full pt-4">
                            {nobrainer.final}
                        </p>
                    </Pulse>
                    <div className="flex items-center text-gray-400 text-sm pt-4">
                        <p className="pr-4">Auch bei Ihnen</p>
                        <HashLink
                            onClick={() => window.fbq("track", "Kontaktbutton Automatisierungspage")}
                            to={ (respoValue >= 640) ?  "#contact" : '/kontakt#_'}
                            className="btn btn-primary btn-outline btn-md w-fit px-4 py-2 h-fit self-center lg:self-start"
                        >
                            Wetten?{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="ml-2 w-6 h-6"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                />
                            </svg>
                        </HashLink>
                    </div>
                </div>
            </section>
            {/* SECTION - EXTRA FEATURES */}
            <section
                id="bonus"
                className="bg-base-100 w-full h-fit flex flex-col items-center pt-12 sm:pt-20 md:py-32 z-20"
            >
                <div className="flex flex-col items-center max-w-screen-lg text-center text-base-200 px-8 sm:px-16">
                    <Headlines big={bonus.headline} />
                    <p className="text-base md:text-lg lg:text-xl">
                        {bonus.text}
                    </p>
                </div>
                <div className="h-fit inline-grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-3 lg:grid-rows-2 gap-0 sm:gap-2 md:gap-8 max-w-screen-xl py-4 md:p-8 w-fit content-center justify-center text-base-200">
                    <ExtraElements
                        icon={gifPhoto}
                        title={bonusElement[0]?.attributes?.headline}
                        description={bonusElement[0]?.attributes?.description}
                        class="w-full sm:w-80"
                    />
                    <ExtraElements
                        icon={gifCam}
                        title={bonusElement[1]?.attributes?.headline}
                        description={bonusElement[1]?.attributes?.description}
                        class="w-full sm:w-80"
                    />
                    <ExtraElements
                        icon={gifDoc}
                        title={bonusElement[2]?.attributes?.headline}
                        description={bonusElement[2]?.attributes?.description}
                        class="w-full sm:w-80"
                    />
                    <ExtraElements
                        icon={gifGlobe}
                        title={bonusElement[4]?.attributes?.headline}
                        description={bonusElement[4]?.attributes?.description}
                        class="lg:col-start-2 lg:col-end-4 flex w-full justify-center lg:row-start-2 lg:row-end-3"
                        customwidth="w-full sm:w-80"
                    />
                    <ExtraElements
                        icon={gifTool}
                        title={bonusElement[3]?.attributes?.headline}
                        description={bonusElement[3]?.attributes?.description}
                        class="md:col-start-1 md:col-end-3 w-full flex justify-center lg:row-start-2 lg:row-end-3"
                        customwidth="w-full sm:w-80"
                    />
                </div>
            </section>
            {/* CONTACT */}
            <div id="contact" className="w-full z-10">
                <ContactForm />
            </div>
        </main>
    );
}


function TheTruth(props) {
    return (
        <div
            className="w-36 sm:w-40 md:w-56 flex flex-col items-center justify-center"
        >
            <p className="text-gray-400 text-xs sm:text-sm">{props.up}</p>
            <p className="uppercase text-primary text-lg sm:text-xl md:text-3xl leading-4 md:leading-7 pb-1 pt- font-bold font-text-headline text-center">
                {props.center}
            </p>
            <p className="text-gray-400 text-sm">{props.down}</p>
        </div>
    );
}
