import { Helmet } from "react-helmet"
import { useEffect } from "react";
import ContactPage from "../page_contact/ContactPage";

export default function ContactPageRoute({ loadingFunc }) {

    useEffect(() => {
        // ReactPixel.init('899428728361041'); 
        // ReactPixel.pageView(); // Für das Tracking von Seitenaufrufen
        window.fbq('track', 'PageView')
        loadingFunc()
    }, []);

    return (
        <section className="router-body">
            <Helmet>
                <title>WICODE - Kontakt</title>
                <link rel='canonical' href='https://wicode.io/kontakt' />
                <meta name='description' content='So erreichen Sie uns bei Fragen, Wünschen oder Anregungen! Wir melden uns umgehend bei Ihnen! Unverbindlich und Kostenlos!' />
            </Helmet>
            <ContactPage loadingFunc={loadingFunc}/>
        </section>
    )
}