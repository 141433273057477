import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DrawerButton from "../special/drawerButton";
import Slide from "react-reveal/Slide";
import NetzBg from "../bg-effects/NetzBg";
import "../../style/automation.css";

import { HashLink } from "react-router-hash-link";
import { API } from "../../App";
import gifIot from "../../res/gifs/iot.gif";
import gifCart from "../../res/gifs/cart.gif";
import gifTeam from "../../res/gifs/teamwhite.gif";
import gifCmd from "../../res/gifs/cmdwhite.gif";
import gifCloud from "../../res/gifs/cloudwhite.gif";

import ExtraElements from "../special/ExtraElements";
import axios from "axios";
import Headlines from "../special/Headlines";

import IMG1 from "../../res/fotos/new/puzzle.png";
import IMG2 from "../../res/fotos/new/6481241-removebg-preview.png";
import gsap, { ScrollTrigger } from "gsap/all";
import de from "hyphenated-de";
import Hyphenated from "react-hyphen";
import ContactForm from "../page_home/components/contactform";

export default function SoftwarePage({ loadingFunc }) {

    const [landing, setLanding] = useState([]);
    const [api, setAPI] = useState([]);
    const [cloud, setCloud] = useState([]);
    const [extra, setExtra] = useState([]);
    const [bonus, setBonus] = useState([]);
    const [bonusElement, setBonusElement] = useState([]);

    // API WORK
    useEffect(() => {
        const fetchData = async () => {
            // LANDING
            await axios
                .get(API + "/api/software")
                .then((res) => setLanding(res.data?.data?.attributes))
                .catch((err) => console.log(err));
    
            // API
            await axios
                .get(API + "/api/api?populate=*")
                .then((res) => setAPI(res?.data?.data?.attributes))
                // .then((res) => console.log(res?.data))
                .catch((err) => console.log(err));
    
            // CLOUD
            await axios
                .get(API + "/api/cloud?populate=*")
                // .then((res) => console.log(res.data?.data?.attributes))
                .then((res) => setCloud(res.data?.data?.attributes))
                .catch((err) => console.log(err));
    
            // EXTRA
            await axios
                .get(API + "/api/extra?populate=*")
                // .then((res) => console.log(res.data?.data?.attributes))
                .then((res) => setExtra(res.data?.data?.attributes))
                .catch((err) => console.log(err));
    
            // BONUS
            await axios
                .get(API + "/api/software-bonus")
                .then((res) => setBonus(res.data?.data?.attributes))
                .catch((err) => console.log(err));
    
            // BONUS ELEMENTS
            await axios
                .get(API + "/api/bonuses")
                .then((res) => setBonusElement(res.data?.data))
                .catch((err) => console.log(err));

            loadingFunc()
        }

        fetchData()
    }, [loadingFunc]);

    const main = useRef();
    const container = useRef();

    useLayoutEffect(() => {
        if (window.innerWidth > 1024) {
            gsap.registerPlugin(ScrollTrigger);
            gsap.defaults({ ease: "none", duration: 2 });
    
            const ctx = gsap.context((self) => {
                const tl = gsap.timeline();
                tl.from("#api", { xPercent: -100 })
                    .from("#cloud-software", { xPercent: 100 })
                    .from("#software-entwicklung", { yPercent: -100 });
    
                ScrollTrigger.create({
                    animation: tl,
                    trigger: container.current,
                    start: "top top",
                    end: "+=4000",
                    scrub: true,
                    pin: true,
                    anticipatePin: 1,
                });
            }, main);
    
            return () => ctx.revert();
        }
    });

    const [respoValue, setRespoValue] = useState(window.innerWidth);

    return (
        <div ref={main} className="overflow-x-hidden relative">
            <DrawerButton />
            <div
                ref={container}
                className="lg:h-screen w-screen overflow-x-hidden flex flex-col lg:relative"
            >
                {/* LANDING */}
                <section
                    id="panel"
                    className="lg:absolute lg:top-0 lg:left-0 lg:h-screen w-screen flex flex-col justify-center items-center"
                >
                    <section
                        id="_"
                        className="w-full h-[48rem] overflow-hidden flex flex-col  justify-center items-center content-center"
                    >
                        <div className="max-w-screen-xl px-4 sm:px-16 xl:px-0 w-full h-fit self-center">
                            <div className="w-fit flex flex-col max-w-3xl items-end pr-2 sm:pr-0">
                                <Slide bottom>
                                    <p className="text-primary font-text-headline font-bold text-lg sm:text-2xl tracking-widest uppercase text-end">
                                        {landing.primary}
                                    </p>
                                </Slide>
                                <Slide left big>
                                    <h1 className="z-10 hidden sm:block sm:text-7xl md:text-8xl text-white font-headline uppercase text-right">
                                        {landing.line1}
                                    </h1>
                                </Slide>
                                <Slide right big>
                                    <h1 className="z-10 text-5xl sm:text-7xl md:text-8xl text-white font-headline uppercase text-end">
                                        {landing.line2}
                                    </h1>
                                </Slide>
                                <Slide top>
                                    <p className="text-white text-end w-full sm:w-4/5 md:w-full max-w-xl text-sm sm:text-base">
                                        {landing.text}
                                    </p>
                                </Slide>
                            </div>
                        </div>
                        <NetzBg />
                    </section>
                </section>
                {/* API PROGRAMMING */}
                <div
                    id="api"
                    className="lg:z-20 lg:absolute lg:top-0 lg:left-0 lg:h-screen w-screen flex flex-col justify-center items-center "
                >
                    <section className="bg-base-100 flex flex-col justify-center items-center w-full lg:h-screen py-12 sm:py-20 md:py-32 px-4">
                        <div className="flex flex-col max-w-screen-xl justify-center items-center">
                            <Headlines
                                big={api.headline}
                                small={api.smallheadline}
                            />
                            <p className="text-justify md:text-center text-base sm:text-lg md:text-xl max-w-5xl py-4">
                                <Hyphenated lang={de}>{api.text}</Hyphenated>
                            </p>
                            <div className="flex flex-col justify-center items-center md:items-start md:flex-row w-full md:justify-evenly lg:justify-between py-4 md:py-8">
                                <div className="flex flex-col w-full px-8 sm:w-96">
                                    <h4 className="text-2xl font-text-headline font-semibold">
                                        Probleme?
                                    </h4>
                                    {api?.problems?.map(item => (
                                        <Facts text={item.prblm} />
                                    ))}
                                </div>
                                <img
                                    src={IMG1}
                                    alt="api"
                                    className="hidden lg:block h-32 w-32"
                                />
                                <div className="absolute h-full w-full top-0 left-0 flex items-center justify-center pt-32 z-0">
                                    <img
                                        src={IMG1}
                                        alt="api"
                                        className="opacity-10 h-80 w-80"
                                    />
                                </div>
                                <div className="flex flex-col w-full px-8 sm:w-96 pt-4 md:pt-0 z-10">
                                    <h4 className="text-2xl font-text-headline font-semibold">
                                        Lösung!
                                    </h4>
                                    {api?.solution?.map(item => (
                                        <Facts yes={true} text={item.sltn} />
                                    ))}
                                    <HashLink
                                        onClick={() => window.fbq("track", "Kontaktbutton 1 Softwarepage")}
                                        to={ (respoValue >= 640) ?  "#contact" : '/kontakt#_'}
                                        className="btn btn-primary btn-outline btn-md lg:btn-lg w-fit px-4 py-2 h-fit self-start mt-4"
                                    >
                                        Nutzen maximieren
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            className="ml-2 w-6 h-6"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                            />
                                        </svg>
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* CLOUD DEVELOPMENT */}
                <div
                    id="cloud-software"
                    className="lg:z-30 lg:absolute lg:top-0 lg:left-0 lg:h-screen w-screen flex flex-col lg:justify-center lg:items-center"
                >
                    <section className="w-full lg:h-screen flex flex-row bg-base-100 overflow-hidden justify-center relative">
                        <div className="hidden w-1/2 grow bg-gradient-to-l to-accent from-base-200 z-0 bg-center lg:flex items-center justify-center">
                            <img
                                src={IMG2}
                                alt="cloud computing"
                                className="h-[80%]"
                            />
                        </div>
                        <img
                            src={IMG2}
                            alt="cloud computing"
                            className="right-0 top-0 lg:hidden absolute opacity-5 sm:opacity-20 h-[60%]"
                        />
                        <div className="hidden lg:block h-[200vh] w-80 rotate-12 absolute bg-base-100 z-10 -top-64 right-[36%]" />
                        <div className="flex flex-col text-base-100 sm:text-base-200 bg-gradient-to-b from-base-200 to-accent sm:bg-none justify-center items-center sm:items-start px-4 sm:px-8 md:px-16 lg:pr-32 lg:px-0 w-full lg:w-1/2 xl:w-[40%] py-12 sm:py-20 md:py-32 z-20">
                            <Headlines
                                big={cloud.headline}
                                small={cloud.smallheadline}
                                left={true}
                            />
                            <div className="pt-2" />
                            {cloud?.problem?.map(item => (
                                <Facts text={item?.prblm} />
                            ))}
                            <p className="text-center sm:text-start w-full font-semibold pt-5 text-lg md:text-xl font-text-headline pr-4">
                                {cloud.text}
                            </p>
                            <p className="text-center sm:text-start w-full font-semibold pb-5 text-lg md:text-xl font-text-headline pr-4">
                                {cloud.text2}
                            </p>
                            {cloud?.solution?.map(item => (
                                <Facts
                                    text={item?.sltn}
                                    yes={true}
                                />
                            ))}
                            <div className="pt-6" />

                            <HashLink
                                onClick={() => window.fbq("track", "Kontaktbutton 2 Softwarepage")}
                                to={ (respoValue >= 640) ?  "#contact" : '/kontakt#_'}
                                className="btn btn-primary btn-outline btn-md lg:btn-lg w-fit px-4 py-2 h-fit self-center sm:self-start lg:self-end lg:mr-16"
                            >
                                Nutzen maximieren
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="ml-2 w-6 h-6"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                    />
                                </svg>
                            </HashLink>
                        </div>
                    </section>
                </div>
                {/* WEITERE */}
                <div
                    id="software-entwicklung"
                    className="lg:z-40 lg:absolute lg:top-0 lg:left-0 lg:h-screen w-screen  flex flex-col justify-center items-center"
                >
                    <section className="w-full flex flex-col bg-base-100 justify-center py-12 sm:py-20 md:py-32 items-center h-full">
                        <div className="w-full max-w-screen-xl flex flex-col items-center justify-center">
                            <Headlines
                                big={extra.headline}
                                small={extra.smallheadline}
                            />
                            <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-6 md:gap-8 w-full px-8 pt-8">
                                {extra?.cards?.map(item => 
                                <FurtherCards
                                    headline={item.headline}
                                    comment={item.text}
                                    icon={item.icon}
                                />
                                )}
                               
                            </div>
                            <p className="font-text-headline text-xl md:text-2xl lg:text-3xl font-semibold pt-6 md:pt-8 lg:pt-12 px-4">
                                {extra.cta}
                            </p>
                            <p className="text-base sm:text-lg lg:text-xl max-w-2xl text-center pb-4 px-4">
                                {extra.text}
                            </p>
                            <HashLink
                                onClick={() => window.fbq("track", "Kontaktbutton 3 Softwarepage")}
                                to={ (respoValue >= 640) ?  "#contact" : '/kontakt#_'}
                                className="btn btn-primary btn-outline btn-md lg:btn-lg w-fit px-4 py-2 h-fit self-center"
                            >
                                Unverbindlich Anfragen!
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="ml-2 w-6 h-6"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                                    />
                                </svg>
                            </HashLink>
                        </div>
                    </section>
                </div>
            </div>
            {/* BONUS */}
            <section
                id="bonus"
                className="z-50 w-full flex flex-col py-12 sm:py-20 md:py-32 justify-center items-center bg-gradient-to-tr from-base-200 to-accent"
            >
                <div className="flex flex-col items-center max-w-screen-lg text-center text-base-100 px-16">
                    <h3 className="uppercase text-3xl sm:text-4xl md:text-5xl font-bold font-headline">
                        {bonus.headline}
                    </h3>
                    <p className="text-base md:text-lg lg:text-2xl">
                        {bonus.text}
                    </p>
                </div>
                <div className="h-fit inline-grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-3 lg:grid-rows-2 gap-0 sm:gap-2 md:gap-8 max-w-screen-xl p-4 md:p-8 w-fit content-center justify-center text-base-100">
                    <ExtraElements
                        icon={gifCloud}
                        title={bonusElement[5]?.attributes?.headline}
                        description={bonusElement[5]?.attributes?.description}
                        class="w-full sm:w-80"
                    />
                    <ExtraElements
                        icon={gifIot}
                        title={bonusElement[6]?.attributes?.headline}
                        description={bonusElement[6]?.attributes?.description}
                        class="w-full sm:w-80"
                    />
                    <ExtraElements
                        icon={gifTeam}
                        title={bonusElement[7]?.attributes?.headline}
                        description={bonusElement[7]?.attributes?.description}
                        class="w-full sm:w-80"
                    />
                    <ExtraElements
                        icon={gifCart}
                        title={bonusElement[9]?.attributes?.headline}
                        description={bonusElement[9]?.attributes?.description}
                        class="lg:col-start-2 lg:col-end-4 flex w-full justify-center lg:row-start-2 lg:row-end-3"
                        customwidth="w-full sm:w-80"
                    />
                    <ExtraElements
                        icon={gifCmd}
                        title={bonusElement[8]?.attributes?.headline}
                        description={bonusElement[8]?.attributes?.description}
                        class="md:col-start-1 md:col-end-3 w-full flex justify-center lg:row-start-2 lg:row-end-3"
                        customwidth="w-full sm:w-80"
                    />
                </div>
            </section>
            <div id="contact" className="w-full z-10">
                <ContactForm />
            </div>
        </div>
    );
}


function Facts(props) {
    return (
        <div className="flex items-center self-start">
            {props.yes ? (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-primary"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                    />
                </svg>
            ) : (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-primary"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            )}
            <p className="pl-4 text-base sm:text-lg w-fit">{props.text}</p>
        </div>
    );
}

function FurtherCards(props) {
    return (
        <div className="bg-white shadow-lg w-full md:w-96 h-fit py-4 xl:h-64 flex flex-row sm:flex-col justify-center items-center px-8">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d={props.icon}
                />
            </svg>
            <h3 className="text-xl sm:text-2xl font-text-headline text-primary pl-2 sm:pl-0">
                {props.headline}
            </h3>
            <p className="hidden sm:block text-sm md:text-base text-center text-gray-400">
                {props.comment}
            </p>
        </div>
    );
}
