import { delay, motion } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { API } from "../../../App";
import { loadFull } from "tsparticles";

export default function LogoSection({ logos, setLogos }) {

    let neworderlogos = useMemo(() => logos,[logos])

    const arraylength = useMemo(() => {
        return Object.keys(neworderlogos).map((key) => Number(key));
    }, [neworderlogos]);

    const [animateArray, setAnimateArray] = useState([]);

    useEffect(() => {
        // console.log('init: ',  logos)
        setAnimateArray(logos?.map(() => false));
    }, [setAnimateArray]);

    useEffect(() => {
        const animateInterval = setInterval(() => {
            handleAnimation()
            setTimeout(() => {
                newSort()
                handleResort()
            }, 2000)
        }, 4000)

        return () => clearInterval(animateInterval)
    })

    async function handleAnimation() {
        let newsortarray = shuffleArray([...arraylength]);

        for (let i in newsortarray) {
            // console.log('hiding process: ',newsortarray[i]);
            updateAnimationArray(newsortarray[i], true);
            await sleep(200);
        }
    }

    function updateAnimationArray(index, newValue) {
        setAnimateArray(prevArray => {
            const updatedArray = [...prevArray];
            updatedArray[index] = newValue;
            return updatedArray;
        });
    }

    function shuffleArray(array) {
        return array.sort(() => Math.random() - 0.5);
    }

    const handleResort = async () => {
        // console.log(animateArray)
        let newsortarray = shuffleArray([...arraylength])

        for (let i in newsortarray) {
            // console.log('appearing process: ', newsortarray[i]);
            updateAnimationArray(newsortarray[i], false);
            await sleep(200);
        }
    }

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const newSort = () => {
        // console.log(neworderlogos)
        // console.log(shuffleArray([...logos]))
        setLogos(shuffleArray([...logos]))
    }
   
    return (
        <div className="absolute w-full h-fit max-w-screen-xl flex flex-row flex-wrap justify-around items-center z-0 gap-x-32 gap-y-10 opacity-10 top-40">
            {neworderlogos.map((item, index) => (
                <motion.img
                    key={index}
                    animate={{
                        scale: animateArray[index] ? 0.5 : 1,
                        opacity: animateArray[index] ? 0 : 1,
                    }}
                    transition={{ duration: 0.6, type: "ease" }}
                    src={
                        API +
                        item?.attributes?.logo?.data[0]?.attributes?.url
                    }
                    alt="scdhfklogo"
                    className="h-28 w-28 sm:h-36 sm:w-36 md:h-48 md:w-48 object-contain content-center"
                />
            ))}
        </div>
    );
}
