
import React from 'react'

export default function Headlines(props) {
  return (
    <div className={`w-fit ${props.color ? 'text-base-100' : ''}`}>
        <h4 className={`font-text-headline text-lg sm:text-xl md:text-2xl tracking-widest text-center font-semibold text-primary`}>{props.small}</h4>
        <h3 className={`uppercase text-3xl sm:text-4xl md:text-5xl  font-headline pb-2 font-extrabold tracking-tight text-center ${props.left ? 'xl:text-start' : ''}`}>{props.big}</h3>
    </div>
                    
  )
}
