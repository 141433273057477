import React from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../../App";
import { HashLink } from "react-router-hash-link";

export default function Footer () {

        return (
            <footer className="footer p-10 bg-base-200 text-base-content shadow-inner flex flex-col justify-center items-center border-t border-base-100/10">
            {/* <div className="h-full bg-no-repeat bg-center bg-fixed -z-20 w-full bg-pig-parallax absolute top-0 left-0" /> */}
                <section className="flex flex-col sm:flex-row justify-center max-w-screen-xl">
                    <h3 className="font-headline text-5xl sm:text-7xl text-center w-full sm:w-fit sm:text-start text-slate-600 px-8">WICODE</h3>
                    <section className="grid grid-cols-1 px-8 items-end w-full sm:w-1/2">
                        <p className="text-white/60 col-span-2 pb-2 text-center sm:text-left"><strong>Ihr Partner für Websites, Automatisierung, Software-Entwicklung und IT-Service in Leipzig und Zwickau!</strong></p>
                        <div className="flex justify-center sm:justify-start w-full text-white/80 text-center sm:text-start">
                            <Link smooth className="link link-hover  px-2 underline" to={`${RoutePath.AUTOMATION}#_`}>
                                Automatisierung
                            </Link>
                            <Link smooth className="link link-hover px-2 underline" to={`${RoutePath.SOFTWARE}#_`}>
                                {window.innerWidth > 810 ? 'Cloud-Software' : 'Cloud'}
                            </Link>
                            <Link smooth className="link link-hover px-2 underline" to={`${RoutePath.BLOG}#_`}>
                                Blog
                            </Link>
                        </div>
                    </section>

                </section>
                <section className="flex flex-col sm:flex-row justify-center max-w-screen-xl">
                    <section className="grid grid-cols-1 px-8 items-end w-full sm:w-1/2">
                        <p className="text-white/60 col-span-2 pb-2 text-center sm:text-right"><strong>Wir haben Ihr Interesse geweckt? Sie haben eine Frage oder Anliegen? Schreiben Sie uns!</strong></p>
                        <div className="flex justify-center sm:justify-end w-full text-white/80 text-center sm:text-start">
                            <Link smooth className="link link-hover text-start px-2 underline" to={`${RoutePath.AGB}#_`}>
                                AGB
                            </Link>
                            <Link smooth className="link link-hover text-start px-2 underline" to={`${RoutePath.IMPRESSUM}#_`}>
                                Impressum
                            </Link>
                            <Link smooth className="link link-hover text-start px-2 underline" to={`${RoutePath.DATENSCHUTZ}#_`}>
                                Datenschutz
                            </Link>
                        </div>
                    </section>
                    <HashLink className="btn place-self-center btn-primary btn-md sm:btn-lg btn-outline mt-4 sm:mt-0"  to={"/#contact"} onClick={()=> {
                        window.fbq('track', 'FooterButton', { eventName: 'buttonClick' })
                    }} >
                        Jetzt Kontaktieren
                        <svg fill="currentColor" viewBox="0 0 16 16" height="2rem" width="2rem" > <path fillRule="evenodd" d="M4 8a.5.5 0 01.5-.5h5.793L8.146 5.354a.5.5 0 11.708-.708l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L10.293 8.5H4.5A.5.5 0 014 8z"/></svg>
                    </HashLink>
                </section>

                
            </footer>
        );
    }

