
import { Helmet } from 'react-helmet';
import { useEffect, useState } from "react";
import BlogPostPage from "../page_blog/BlogPostPage";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API } from '../../App';

export default function BlogPageRoute ({ loadingFunc }) {

    const { id } = useParams()
    const [blog, setblog] = useState()

    useEffect(() => {
        window.fbq('track', 'PageView')

        const fetchData = async () => {
            await axios
                .get(API + '/api/blogposts/' + id + '?populate=*')
                .then(res => setblog(res.data.data.attributes))
                .catch(err => console.log(err))
            
                loadingFunc()
        }

        fetchData()
    }, []);

    return(
        <section className="router-body">
            <Helmet>
                <title>{blog?.seo?.metaTitle ?? ''} | Dresden, Leipzig & Zwickau</title>
                <link rel='canonical' href={'https://wicode.io/blog/' + id + '/' + blog?.title ?? ''} />
                <meta name='description' content={blog?.seo?.metaDescription} />
                <meta name='keywords' content={blog?.seo?.keywords} />
            </Helmet>
            <BlogPostPage blog={blog}/>
        </section>
    )
}
