
import React from 'react'
import Slide from 'react-reveal/Fade';

export default function ExtraElements(props) {
  return (
    
    <Slide bottom cascade>
      <div className={`${props.class}`}>
          <div className={`h-fit flex flex-col items-center py-4 cursor-text ${props.customwidth}`} style={{ pointerEvents: 'Text'}}>
              <img src={props.icon} alt='IT Website' style={{height: 100, width: 100}} />
              <h3 className='uppercase text-2xl lg:text-3xl font-bold pt-2 pb-1 font-text-headline'>{props.title}</h3>
              <p className='text-sm sm:text-base text-center px-4 leading-6'>{props.description}</p>
          </div>
      </div>
    </Slide>
  )
}
