import { Splide, SplideSlide } from '@splidejs/react-splide'
import React, { useEffect, useRef, useState } from 'react'
import { API } from '../../../App';
import dayjs from 'dayjs';

export default function CusotmerSlider({ testimonials, bg }) {
    const splideRef = useRef(null);

    const handlePrev = () => {
        if (splideRef.current) {
            splideRef.current.splide.go("-1"); // Go to the previous slide
        }
    };

    const handleNext = () => {
        if (splideRef.current) {
            splideRef.current.splide.go("+1"); // Go to the next slide
        }
    };

    return (
        <div className='w-full px-8 py-4 md:p-16 h-fit flex justify-center z-10'>
            <div className='w-full max-w-screen-lg shadow-lg bg-white/20 rounded-lg h-fit flex justify-center'>
                <Splide
                    options={{
                        perPage: 1,
                        type: 'loop',
                        perMove: 1,
                        autoplay: true,
                        width: "96%",
                        arrows: false,
                        pagination: true,
                    }}
                    ref={splideRef}
                >
                    {testimonials?.map((slide, index) => (
                        <SplideSlide key={index}>
                            <div className="flex items-center justify-center h-full lg:min-h-[24rem] p-4 pb-8 md:pb-0 sm:p-0 lg:p-8">
                                <Sliderpost
                                    url={
                                        API + slide?.attributes?.img1?.data?.attributes?.url
                                    }
                                    alt={API + slide?.attributes?.img1?.data?.attributes?.name}
                                    id={slide?.id}
                                    headline={slide?.attributes?.headline}
                                    content={slide?.attributes?.text}
                                    small={slide?.attributes?.size}
                                    date={dayjs(slide?.attributes?.publishedAt).format(
                                        "DD.MM.YYYY"
                                    )}
                                />
                            </div>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
        </div>
    )
}



function Sliderpost(props) {
    return (
        <div className="h-full w-full flex flex-row items-center lg:p-8">
            <div className='w-80 hidden md:flex justify-end items-center h-full'>
                <img
                    alt={props.alt}
                    src={props.url}
                    className={` ${props.small ? 'w-48 h-48' : 'h-fit w-full max-w-full max-h-80'} rounded-3xl`}
                />
            </div>
            <div className="flex flex-col justify-center w-full md:w-3/5 items-baseline md:pl-12 md:pr-4 text-base-200 h-full">
                <h4 className="truncate font-bold uppercase font-headline">{props.headline}</h4>
                <p className="text-md font-semibold pt-2 md:p-2 line-clamp-[10] md:line-clamp-none">
                    {props.content}
                </p>            
            </div>
        </div>
    );
}
