
import React from 'react'

export default function ScrollingElement(props) {
    return (
        <div
            className={`sm:h-64 sm:max-w-[24rem] md:mb-8 w-full shadow-lg flex p-8 flex-col items-center justify-center z-10 md:min-w-[20rem] ${
                props.color !== "b"
                    ? "bg-base-100 text-base-200"
                    : "bg-base-200 text-base-100"
            }`}
        >
            <h4 className="text-2xl font-bold text-primary ">{props.count}</h4>
            <h4 className="text-lg uppercase tracking-widest font-semibold text-center">
                {props.headline}
            </h4>
            {props.bool ? (
                <div className="border-b border-primary w-32 my-2" />
            ) : (
                ""
            )}
            <p className="text-center text-base text-white/80">{props.text}</p>
        </div>
    );
}
