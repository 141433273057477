import React from "react";

export default function MethodGridElement(props) {
    return (
        <div className="w-64 h-96 flex shadow-xl rounded-md flex-col items-center p-8">
            <img
                src={props.img}
                alt="Methode"
                className="h-24 w-24 rounded-full"
            />
            <p className="py-2 text-lg uppercase font-headline">
                {props.headline}
            </p>
            <p className="text-center text-base">{props.text}</p>
        </div>
    );
}
