import { Helmet } from "react-helmet";
import AutomationPage from "../page_webdev/AutomationPage";
import { useEffect } from "react";
import axios from "axios";
import { API } from '../../App.js'
import { useState } from "react";

export default function AutomationPageRoute({ loadingFunc }) {
    
    const [page, setpage] = useState()

    useEffect(() => {
        const fetchData = async () => {
            await axios    
                .get(API + '/api/seo?populate=*')
                .then(res => setpage(res.data.data.attributes.automatisierung))
                .catch(err => console.log(err))
            loadingFunc()
        }
        
        fetchData()
        window.fbq('track', 'Aufruf Automatisierungspage')
    }, [loadingFunc]);
    
    return (
        <section className="router-body">
            <Helmet>
                <title>{page?.metaTitle ?? 'Wicode Automatisierung'}</title>
                <link rel='canonical' href={ page?.canonicalURL ?? 'https://wicode.io/automatisierung'} />
                <meta name='description' content={page?.metaDescription ?? 'Um Zeit und Geld im Tagesgeschäft zu sparen, optimieren wir Ihre Prozesse und automatisieren unnötige Aufgaben! Zeitfresser, Papierkram & Excel-Tabellen sind Geschichte!'} />
                <meta name='keywords' content={page?.keywords ?? 'Wicode, Prozessautomatisierung, Automatisierung, Prozessoptimierung, Prozesse, automatisieren, optimieren, Software, Dresden, Leipzig, Zwickau'} />
            </Helmet>
            <AutomationPage loadingFunc={loadingFunc}/>
        </section>
    );
}
