import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../../App";
import { useDrawerContext } from "./DrawerContext";
import InstagramSVG from "../../res/svgs/InstagramSVG";
import LinkedinSVG from "../../res/svgs/LinkedinSVG";
import CallSVG from "../../res/svgs/CallSVG";
import MessageSVG from "../../res/svgs/MessageSVG";

export default function DrawerList() {
    const { open, cssHidden, trigger } = useDrawerContext();

    const [clickDesign, setClickDesign] = useState(false);
    const [clickSoftware, setClickSoftware] = useState(false);
    const [clickService, setClickService] = useState(false);
    const [clickUnternehmen, setClickUnternehmen] = useState(false);
    const [clickBlog, setClickBlog] = useState(false);
    const [clickSmth, setClickSmth] = useState(false);

    const handleClick = (props) => {
        setClickSmth(!clickSmth);
        if (props === "design") {
            setClickDesign(!clickDesign);
            setClickSoftware(false);
            setClickService(false);
            setClickUnternehmen(false);
            setClickUnternehmen(false);
        } else if (props === "software") {
            setClickDesign(false);
            setClickSoftware(!clickSoftware);
            setClickService(false);
            setClickUnternehmen(false);
            setClickUnternehmen(false);
        } else if (props === "service") {
            setClickDesign(false);
            setClickSoftware(false);
            setClickService(!clickService);
            setClickUnternehmen(false);
            setClickUnternehmen(false);
        } else if (props === "unternehmen") {
            setClickDesign(false);
            setClickSoftware(false);
            setClickService(false);
            setClickUnternehmen(!clickUnternehmen);
            setClickUnternehmen(false);
        } else if (props === "blog") {
            setClickDesign(false);
            setClickSoftware(false);
            setClickService(false);
            setClickUnternehmen(false);
            setClickUnternehmen(!clickBlog);
        }
    };


    return (
        // <div className="h-full overflow-y-scroll">
        <motion.div
            className={`fixed flex-row h-screen w-screen overflow-hidden z-[1000] ${
                cssHidden ? "hidden" : "flex"
            }`}
        >
            <motion.nav
                initial={{
                    x: "-100%",
                }}
                animate={{
                    x: open ? "0%" : "-100%",
                }}
                transition={{
                    duration: 0.25,
                    type: "ease",
                }}
                className="absolute flex flex-col w-80 border-r-2 border-primary text-base-content h-full bg-base-100 overflow-y-hidden"
            >
                {/* <!-- Sidebar content here --> */}
                <nav className="w-full static flex justify-center h-24 px-4 pt-6 pb-4 items-center font-headline uppercase text-4xl z-50 bg-base-100">
                    <Link
                        to={RoutePath.HOME + "#_"}
                        onClick={() =>
                            (document.getElementById(
                                "my-drawer"
                            ).checked = false)
                        }
                    >
                        WICODE
                    </Link>
                </nav>
                {/* Home Nav */}
                <nav
                    className="w-full cursor-pointer static flex items-center px-4 py-4 h-16 overflow-hidden z-50 bg-base-100"
                    onClick={() => {
                        handleClick();
                    }}
                >
                    <label
                        className="text-2xl uppercase font-normal flex justify-between bg-base-100 cursor-pointer"
                        href="/"
                    >
                        <Link
                            to={RoutePath.HOME + "#_"}
                            className="hover:underline cursor-pointer"
                            onClick={() =>
                                (document.getElementById(
                                    "my-drawer"
                                ).checked = false)
                            }
                        >
                            Homepage
                        </Link>
                    </label>
                </nav>
                {/* Automatisierung Nav */}
                <nav className="w-full static flex items-center px-4 py-4 h-16 overflow-hidden z-50 bg-base-100">
                    <label
                        className="text-2xl w-full uppercase font-normal flex justify-between items-center bg-base-100"
                        href="/"
                    >
                        <Link
                            className="cursor-pointer hover:underline"
                            to={RoutePath.AUTOMATION + "#_"}
                            onClick={() =>
                                (document.getElementById(
                                    "my-drawer"
                                ).checked = false)
                            }
                        >
                            Automatisierung
                        </Link>
                        {/* <motion.div
                            animate={clickDesign ? "rotated" : "unrotated"}
                            className="btn btn-circle btn-ghost"
                            onClick={() => {
                                handleClick("design");
                            }}
                        >
                            <motion.div variants={variants}>
                                <svg
                                    className="w-6 h-6 "
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                    />
                                </svg>
                            </motion.div>
                        </motion.div> */}
                    </label>
                </nav>
                {/* <motion.div
                    animate={{
                        height: clickDesign ? "" : "0%",
                    }}
                    transition={{
                        type: "ease",
                        duration: 0.5,
                    }}
                    className={`${clickSmth && !clickDesign ? "hidden" : ""}`}
                >
                    <div className="w-full text-xl p-0 relative bg-base-100">
                        <ul className="w-full flex flex-col items-center">
                            <nav className="border-t w-4/5 border-gray-200"></nav>
                            <Link
                                onClick={() =>
                                    (document.getElementById(
                                        "my-drawer"
                                    ).checked = false)
                                }
                                className="w-full pl-8 py-4 hover:bg-black/5 cursor-pointer"
                                to={"/automatisierung#probleme"}
                                smooth={true}
                            >
                                Probleme
                            </Link>
                            <Link
                                onClick={() =>
                                    (document.getElementById(
                                        "my-drawer"
                                    ).checked = false)
                                }
                                className="w-full pl-8 py-4 hover:bg-black/5 cursor-pointer"
                                to={"/automatisierung#methode"}
                                smooth={true}
                            >
                                Methode
                            </Link>
                            <Link
                                onClick={() =>
                                    (document.getElementById(
                                        "my-drawer"
                                    ).checked = false)
                                }
                                className="w-full pl-8 py-4 hover:bg-black/5 cursor-pointer"
                                to={"/automatisierung#no-brainer"}
                                smooth
                            >
                                No Brainer
                            </Link>
                            <Link
                                onClick={() =>
                                    (document.getElementById(
                                        "my-drawer"
                                    ).checked = false)
                                }
                                className="w-full py-4 pl-8 hover:bg-black/5 cursor-pointer"
                                to={"/automatisierung#bonus"}
                                smooth={true}
                            >
                                Bonus
                            </Link>
                        </ul>
                    </div>
                </motion.div> */}
                {/* Software Nav */}
                <nav className="w-full static flex items-center px-4 py-4 h-16 overflow-hidden z-50 bg-base-100">
                    <label
                        className="text-2xl w-full uppercase font-normal flex justify-between bg-base-100 items-center"
                        href="/"
                    >
                        <Link
                            className="cursor-pointer hover:underline"
                            to={RoutePath.SOFTWARE + "#_"}
                            onClick={() =>
                                (document.getElementById(
                                    "my-drawer"
                                ).checked = false)
                            }
                        >
                            Software
                        </Link>
                    </label>
                </nav>
                {/* Über Uns */}
                {/* <nav className="w-full static flex items-center px-4 py-4 h-16 overflow-hidden z-50 bg-base-100">
                    <label
                        className="text-2xl w-full uppercase font-normal flex justify-between bg-base-100 items-center"
                        href="/unternehmen"
                    >
                        <Link
                            className="cursor-pointer hover:underline"
                            to={RoutePath.ABOUT + "#_"}
                            onClick={() =>
                                (document.getElementById(
                                    "my-drawer"
                                ).checked = false)
                            }
                        >
                            Über Uns
                        </Link>
                    </label>
                </nav> */}
                {/* Blog Nav */}
                <nav className="w-full cursor-pointer static flex items-center px-4 py-4 h-16 hover:bg-base-100/80 overflow-hidden z-50 bg-base-100">
                    <label className="text-2xl w-full uppercase font-normal flex justify-between bg-base-100 cursor-pointer">
                        <Link
                            to={"/blog#_"}
                            className="hover:underline cursor-pointer"
                            onClick={() =>
                                (document.getElementById(
                                    "my-drawer"
                                ).checked = false)
                            }
                        >
                            Blog
                        </Link>
                    </label>
                </nav>
                {/* FAQ Nav */}
                <nav className="w-full cursor-pointer static flex items-center px-4 py-4 h-16 hover:bg-base-100/80 overflow-hidden z-50 bg-base-100">
                    <label className="text-2xl w-full uppercase font-normal flex justify-between bg-base-100 cursor-pointer">
                        <Link
                            to={"/faq#_"}
                            className="hover:underline cursor-pointer"
                            onClick={() =>
                                (document.getElementById(
                                    "my-drawer"
                                ).checked = false)
                            }
                        >
                            FAQ
                        </Link>
                    </label>
                </nav>
                {/* Kontakt Nav */}
                <nav className="w-full cursor-pointer static flex items-center px-4 py-4 h-16 hover:bg-base-100/80 overflow-hidden z-50 bg-base-100">
                    <label className="text-2xl w-full uppercase font-normal flex justify-between bg-base-100 cursor-pointer">
                        <Link
                            to={"/kontakt"}
                            className="hover:underline cursor-pointer"
                            onClick={() =>
                                (document.getElementById(
                                    "my-drawer"
                                ).checked = false)
                            }
                        >
                            Kontakt
                        </Link>
                    </label>
                </nav>
                {/* White Space */}
                <div className={`w-full bg-base-100 z-40 grow`}/>
                {/* Impressum & Datenschutz */}
                <div className="h-fit w-full text-gray-400 underline flex flex-row justify-evenly items-center">
                    <Link smooth className="link link-hover" to={`${RoutePath.IMPRESSUM}#_`}>
                        Impressum
                    </Link>
                    <Link smooth className="link link-hover" to={`${RoutePath.DATENSCHUTZ}#_`}>
                        Datenschutz
                    </Link>
                </div>
                {/* Links to Social Media */}
                <div className="h-fit pb-4 pt-2 w-full text-gray-400  flex flex-row justify-center items-center">
                    <InstagramSVG />
                    <LinkedinSVG />
                    <CallSVG />
                    <MessageSVG />
                </div>
            </motion.nav>
            <motion.div
                initial={{
                    opacity: 0,
                }}
                animate={{
                    opacity: open ? 1 : 0,
                }}
                transition={{
                    duration: 0.05,
                    type: "ease",
                }}
                className="h-full w-screen bg-base-200/60 cursor-pointer"
                onClick={trigger}
            />
        </motion.div>
        // </div>
    );
}
