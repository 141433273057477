
import React from 'react'
import '../../style/loading.css'

export default function LoadingScreen() {
  return (
    <section className='w-screen h-screen absolute top-0 left-0 bg-base-100 flex justify-center items-center z-[100000]'>
        <div class="loadingspinner">
        <div id="square1"></div>
        <div id="square2"></div>
        <div id="square3"></div>
        <div id="square4"></div>
        <div id="square5"></div>
        </div>
    </section>
  )
}
