
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

export default function LatestPost({ imglink, content, title, dateD, alt, id }) {
    const navigate = useNavigate()
    const handleClick = (link) => {
        navigate(link)
    }
    return (
        <div className='border mt-4 mb-8 md:mt-10 md:mb-24 w-full h-fit md:h-96 p-8 flex flex-col md:flex-row gap-4 bg-white shadow-lg ' onClick={() => handleClick('/blog/' + id + '/' + title + '#_')}>
            <div className='h-64 w-64 self-center md:h-full md:max-h-full md:w-full max-w-xs lg:max-w-sm bg-cover bg-center rounded-sm' style={{ backgroundImage: `url(${imglink})` }}/>
            <div className='grow h-full px-8 sm:px-12 md:px-16 flex flex-col justify-center'>
                <p className='hidden md:block text-xs self-end pr-24'>vom {dateD}</p>
                <h3 className='text-lg sm:text-xl md:text-2xl text-center md:text-start underline font-bold py-1'>{title}</h3>
                <p className='line-clamp-2 text-center md:text-start md:line-clamp-3 max-w-lg text-sm sm:text-base'>{content}</p>
                <HashLink className='hidden md:block self-end pr-24 underline text-sm' to={'/blog/' + id + '/' + title + '#_'}>...mehr lesen</HashLink>
            </div>
        </div>
    )
}
