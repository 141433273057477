
import React from 'react'
import DrawerButton from '../special/drawerButton'
import ContactForm from '../page_home/components/contactform'

export default function ContactPage() {
    return (
        <main>
            <DrawerButton />
            <ContactForm />
        </main>
    )
}
