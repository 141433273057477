import React, { createContext, useState } from "react";

const DrawerContext = createContext();

export const useDrawerContext = () => React.useContext(DrawerContext)

export const DrawerContextCode = ({children}) => {
    const [open, setOpen] = useState(false)
    const [cssHidden, setCssHidden] = useState(true)

    const trigger = (bool) => {
        if (open) {
            setOpen(!open)
            setTimeout(() => {
                setCssHidden(!cssHidden)
            }, 150)
        }
        else {
            setCssHidden(!cssHidden)
            setOpen(!open)
        }
    }


    return(
        <DrawerContext.Provider value={{ open, cssHidden, setOpen, trigger }}>
            {children}
        </DrawerContext.Provider>
    )
}
