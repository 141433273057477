import { useEffect, useState } from "react";
import DrawerButton from "../special/drawerButton";
import axios from "axios";
import { API } from "../../App";
import ReactMarkdown from "react-markdown";
import { customComponents } from "./markdownCustom";


export default function Impressum({loadingFunc}) {

    const [impressum, setimpressum] = useState()
    useEffect(() => {   
        const fetchdata = async () => {
            await axios.get(API + '/api/impressum')
            .then(res => setimpressum(res.data.data.attributes.content))
            .catch(err => console.log(err))

            loadingFunc()
        }

        fetchdata()
    }, [])

    return(
        <section id="_" className="px-16 py-24 flex flex-col h-screen w-full">
            <DrawerButton />
            <h1 className="text-2xl font-semibold underline">Impressum</h1>
            <div className=" px-2 pt-2 w-full h-full">
                <ReactMarkdown components={customComponents}>
                    {impressum}
                </ReactMarkdown>
            </div>
        </section>
    )
}