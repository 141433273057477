import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function NotFound({ loadingFunc }) {
    useEffect(() => {
        loadingFunc(false)
    })

    useEffect(() => {
        window.fbq('track', 'Error Page')
    }, []);

    return (
        <div className="w-full grow flex justify-center items-center h-screen flex-col">
            <Helmet>
                <link rel="canonical" href="https://wicode.io/404" />
                <title>Page not found</title>
                <meta name="errorpage" content="true" />
                <meta name="errortype" content="404 - Not Found" />
                <meta name="prerender-status-code" content="404" />
            </Helmet>
            <h1 className="text-2xl underline font-semibold">404-Error</h1>
            <p className="py-2">Die angeforderte Seite konnte nicht gefunden werden.</p>
            <Link to='/' className="btn btn-primary btn-outline">zurück zur Homepage</Link>
        </div>
    );
}
