
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Slide from "react-reveal/Slide";
import NetzBg from '../bg-effects/NetzBg'
import gsap, { ScrollTrigger } from "gsap/all";
import Headlines from '../special/Headlines';
import IMG1 from '../../res/fotos/analysis.jpg'
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { API } from '../../App';
import { customComponents } from '../common/markdownCustom';

export default function AboutPage({ loadingFunc }) {

    
    const main = useRef();
    const container = useRef();

    const [landing, setlanding] = useState()
    const [werte, setwerte] = useState()
    const [imgdata, setimgdata] = useState()
    const [anliegen, setanliegen] = useState()

    useEffect(() => {
        const fetchData = async () => {
            await axios
                .get(API + '/api/aboutus-landing')
                .then(res => setlanding(res?.data?.data?.attributes))
                .catch(err => console.log(err))
            await axios
                .get(API + '/api/aboutus-werte?populate=*')
                .then(res => setwerte(res?.data?.data?.attributes))
                .catch(err => console.log(err))
            await axios
                .get(API + '/api/aboutus-img?populate=*')
                .then(res => setimgdata(res?.data?.data?.attributes))
                .catch(err => console.log(err))
            await axios
                .get(API + '/api/aboutus-anliegen')
                .then(res => setanliegen(res.data.data.attributes))
                .catch(err => console.log(err))
            loadingFunc()
        }

        fetchData()
    }, [loadingFunc])

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        let sections = gsap.utils.toArray('#panel')                     // <-- pastes every element with id=panel in an array
        const ctx = gsap.context((self) => {                            // <-- defines gsap animation context
            gsap.to(sections, {                                         
                xPercent: -100 * (sections.length - 1),                 // <-- ???
                ease: true,                                           
                scrollTrigger: {
                    trigger: container.current,
                    pin: true,
                    scrub: true,                                        // <-- ???
                    normalize: true,
                    // snap: 1 / (sections.length - 1),                 // <-- creates the jumping-to-screen-effect
                    end: () => '+='+ container.current.offsetWidth
                }
            });
        }, main);

        return () => ctx.revert();
    })

    return (
        <main id="_" ref={main} className="overflow-x-hidden ">
            {/* LANDING */}
            <div ref={container} className='h-screen w-fit overflow-x-hidden relative flex'>
                <section
                    id="panel"
                        className="h-screen w-screen flex flex-col justify-center items-center"
                    >
                        <section
                            id="_"
                            className="w-full h-[48rem] overflow-hidden flex flex-col  justify-center items-center content-center"
                        >
                            <div className="max-w-screen-xl px-4 sm:px-16 xl:px-0 w-full h-fit self-end">
                                <div className="flex flex-col w-full max-w-3xl items-end pr-2 sm:pr-0">
                                    <Slide bottom>
                                        <p className="text-primary font-text-headline font-bold text-lg sm:text-2xl tracking-widest uppercase text-end">
                                            {landing?.headline_small}
                                        </p>
                                    </Slide>
                                    <div className='flex flex-row'>

                                    <Slide left big>
                                        <h1 className="hidden sm:block sm:text-7xl md:text-8xl text-white font-headline uppercase text-right">
                                            {landing?.headline1}
                                        </h1>
                                    </Slide>
                                    <Slide right big>
                                        <h1 className="pl-8 text-5xl sm:text-7xl md:text-8xl text-white font-headline uppercase text-end">
                                            {landing?.headline2}
                                        </h1>
                                    </Slide>
                                    </div>
                                    <Slide top>
                                        <p className="text-white text-end w-full sm:w-4/5 md:w-full max-w-xl text-sm sm:text-base">
                                            {landing?.description}
                                        </p>
                                    </Slide>
                                </div>
                            </div>
                            <NetzBg />
                        </section>
                </section>
                {/* HEAD TEAM */}
{/*                 
                <section
                    id="panel"
                    className="absolute top-0 left-0 h-screen w-fit flex flex-col pl-8 justify-center items-start pr-32"
                >
                    <div className='flex flex-row h-2/3 relative w-[32rem] '>
                        <div className='w-32 absolute left-0 h-full bg-gradient-to-r from-base-100'/>
                        <img src={IMG1} alt='teammember1' className='h-full w-full object-cover' />
                        <div className='w-32 absolute right-0 h-full bg-gradient-to-l from-base-100'/>
                    </div>
                    <div className='flex flex-col w-[32rem] justify-center'>
                        <Headlines big="Felix Schober"/>
                        <div className='w-full flex justify-center'>
                            <div className='w-[80%] border-b border-gray-300  mb-3'/>
                        </div>
                        <p className='text-xl text-gray-400 w-full text-right'>Gründungspartner &</p>
                        <p className='text-xl text-gray-400 w-full text-right'>Head of IT</p>
                    </div>
                </section> */}
                {/* Unsere Werte */}
                <section
                    id="panel"
                    className="h-screen w-screen flex flex-row justify-center items-center pr-32 bg-base-100"
                >
                    <Values keyword={werte?.wert1?.keyword} word1={werte?.wert1?.word1} word2={werte?.wert1?.word2} content={werte?.wert1?.content}/>
                    <Values keyword={werte?.wert2?.keyword} word1={werte?.wert2?.word1} word2={werte?.wert2?.word2} content={werte?.wert2?.content}/>
                    <Values keyword={werte?.wert3?.keyword} word1={werte?.wert3?.word1} word2={werte?.wert3?.word2} content={werte?.wert3?.content}/>
                </section> 
                {/* IMG + One Liner */}
                <section
                    id="panel"
                    className="h-screen w-screen bg-gray-400 flex flex-row justify-center items-center"
                >
                    <div className='flex flex-col justify-center items-center h-screen w-[40rem]' >
                        <img src={API + imgdata?.img?.data[0]?.attributes?.url} alt='aboutus' className='h-full w-[40rem] object-cover'/>
                        <div className='h-full w-[40rem] z-0 absolute bg-black/70'/>
                        <p className='text-5xl font-text-headline uppercase z-10 text-base-100 text-center font-bold px-8 absolute'>{imgdata?.slogan}</p>
                    </div>
                    <section
                        className="h-screen grow flex flex-col justify-center items-center px-48 bg-base-100"
                    >
                        <div className='max-w-2xl w-full'>
                        <Headlines big={anliegen?.headline} small={anliegen?.headline_small} left={true}/>
                        </div>
                        <p className='pt-4 text-xl max-w-2xl'>
                            <ReactMarkdown components={customComponents}>
                                {anliegen?.content}
                            </ReactMarkdown>
                        </p>
                    </section>
                </section>
                <div id="panel" className='h-screen w-screen bg-orange-500 flex flex-col justify-center items-center '>
                    <section
                    className="h-full w-fit flex flex-col pl-8 justify-center items-start pr-32"
                >
                    <div className='flex flex-row h-2/3 relative w-[32rem] '>
                        <div className='w-32 absolute left-0 h-full bg-gradient-to-r from-base-100'/>
                        <img src={IMG1} alt='teammember1' className='h-full w-full object-cover' />
                        <div className='w-32 absolute right-0 h-full bg-gradient-to-l from-base-100'/>
                    </div>
                    <div className='flex flex-col w-[32rem] justify-center'>
                        <Headlines big="Niklas Riedel"/>
                        <div className='w-full flex justify-center'>
                            <div className='w-[80%] border-b border-gray-300  mb-3'/>
                        </div>
                        <p className='text-xl text-gray-400 w-full text-right'>Gründungspartner &</p>
                        <p className='text-xl text-gray-400 w-full text-right'>Geschäftsführer</p>
                    </div>
                </section>
                </div>
                <div id="panel" className='h-screen w-screen bg-red-500 flex flex-col justify-center items-center'>
                    <p className='text-lg text-black font-bold underline'>Helloooo, that is my third Container!</p>
                </div>
                <div id="panel" className='h-screen w-screen bg-blue-500 flex flex-col justify-center items-center'>
                    <p className='text-lg text-black font-bold underline'>Helloooo, that is my fourth Container!</p>
                </div>
                <div id="panel" className='h-screen w-screen bg-yellow-500 flex flex-col justify-center items-center'>
                    <p className='text-lg text-black font-bold underline'>Helloooo, that is my fourth Container!</p>
                </div>
            </div>
        </main>
    )
}


function Values (props) {
    return(
        <div className='flex flex-col justify-center items-end w-full max-w-lg'>
            <h4 className='text-4xl font-text-headline text-right font-bold'>{props.keyword}</h4>
            <div className='border-b w-72 mb-2 mt-3'/>
            <h3 className='text-primary text-8xl font-headline uppercase tracking-tight leading-[5rem] text-right'>{props.word1}<br/>{props.word2}</h3>
            <p className='w-80 text-right pt-4 font-semibold'>
            {props.content}
            </p>
        </div>
    )
}