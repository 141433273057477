
import { Helmet } from 'react-helmet';
import { useEffect, useState } from "react";
import BlogPage from "../page_blog/BlogPage";
import axios from "axios";
import { API } from '../../App.js'

export default function BlogPageRoute ({ loadingFunc }) {

    const [page, setpage] = useState()

    useEffect(() => {
        window.fbq("track", "Aufruf Blog");
        
        const fetchData = async () => {
            await axios    
                .get(API + '/api/seo?populate=*')
                .then(res => setpage(res.data.data.attributes.blog))
                .catch(err => console.log(err))
            loadingFunc()
        }

        fetchData()
        window.fbq('track', 'PageView')
    }, [loadingFunc]);

    return(
        <section className="router-body">
            <Helmet>
                <title>{ page?.metaTitle ?? 'Wicode News'}</title>
                <link rel='canonical' href={ page?.canonicalURL ?? 'https://wicode.io/blog'} />
                <meta name='description' content={page?.metaDescription ?? 'News & Aufklärung über Wicode, Automatisierung und Software'} />
                <meta name='keywords' content={ page?.keywords ?? 'Prozessautomatisierung, Automatisierung, Geschäftsprozessautomatisierung, Unternehmen automatisieren, Automatisierung für KMU, Automatisierung für Unternehmen, Prozessoptimierung, Automation, Software, App entwickeln lassen, WebApps entwickeln lassen, Webapp, App, App entwickeln, Software programmieren'} />
            </Helmet>
            <BlogPage loadingFunc={loadingFunc}/>
        </section>
    )
}
