import { useState } from 'react'
import { Fade } from "react-reveal";
import DrawerButton from "../special/drawerButton";
import NetzBg from "../bg-effects/NetzBg";
import {motion} from 'framer-motion'
import Headlines from '../special/Headlines';

export default function FaqPage({ faq }) {


    const [open, setopen] = useState('')
    const handleClick = (e) => {
        if (open === e) {
            setopen('')
        } else {
            setopen(e)
        }
    }

    return (
        <main className="min-h-screen h-auto flex flex-col items-center">
            <DrawerButton />
            <section
                id="_"
                className="w-full h-fit overflow-hidden flex flex-col justify-center items-start content-center"
            >
                <Fade left>
                    <h1 className="z-10 text-5xl sm:text-7xl md:text-8xl text-white font-headline uppercase text-start p-12 sm:p-28 md:p-48">
                        Fragen & Antworten
                    </h1>
                </Fade>
                <NetzBg />
            </section>
            <section className='w-full flex flex-col items-center bg-base-100 py-16'>
                <div className='w-full max-w-screen-lg flex flex-col items-center'>
                    <Headlines small="Good 2 Know" big="Allgemeine Fragen" />
                    {faq?.map((item, index) => (
                        item?.attributes?.section === 1 && 
                            <SimpleAccord key={index} bool={open === item?.attributes?.title} func={() => handleClick(item?.attributes?.title)} headline={item?.attributes?.title} text={item?.attributes?.content}/>
                    ))}
                    <div className='mt-16'/>
                    <Headlines small="Good 2 Know" big="Entwicklung & Projektablauf" />
                    {faq?.map((item, index) => (
                        item?.attributes?.section === Number(2) && 
                            <SimpleAccord key={index} bool={open === item?.attributes?.title} func={() => handleClick(item?.attributes?.title)} headline={item?.attributes?.title} text={item?.attributes?.content}/>
                    ))}
                    <div className='mt-16'/>
                    <Headlines small="Good 2 Know" big="Fragen zu Wicode" />
                    {faq?.map((item, index) => (
                        item?.attributes?.section === 3 &&
                            <SimpleAccord key={index} bool={open === item?.attributes?.title} func={() => handleClick(item?.attributes?.title)} headline={item?.attributes?.title} text={item?.attributes?.content}/>
                    ))}
                </div>
            </section>
        </main>
    )
}


function SimpleAccord (props) {
    return(
        <div className='w-full flex flex-col overflow-hidden static overflow-y-hidden px-4 py-2'>
            <div className='h-4 z-10 bg-base-100'/>
            <div onClick={props.func} className='w-full flex flex-row justify-between items-center uppercase border-b border-base-200 p-4 font-medium z-10'>
                <p className='w-[90%]'>
                    {props.headline}
                </p>
                <motion.svg 
                    initial={{
                        rotate: 0
                    }}
                    animate={{
                        rotate: props.bool ? 180 : 0
                    }}
                    onClick={props.func} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 z-50 cursor-pointer"><path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></motion.svg>
            </div>

            <motion.div
                animate={{ 
                    height: props.bool ? '0%' : ''
                }}
                transition={{
                    duration: 0.25,
                    type: 'ease'
                }}
                className='h-0'
                >
                    <p className='relative w-full p-4 shadow-md' >
                    {props.text}

                    {/* Hello People */}
                    </p>
            </motion.div>
           
            </div>
    )
}