import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../App";
import ReactMarkdown from "react-markdown";
import { customComponents } from "./markdownCustom";
import DrawerButton from "../special/drawerButton";
import { handleDownload } from "../../services/download";

export default function Agb({ loadingFunc }) {
    const [agbData, setagbData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await axios
                .get(API + "/api/agb?populate=*")
                .then((res) => setagbData(res.data?.data?.attributes))
                .catch((err) => console.log("Error: ", err));

            loadingFunc();
        };

        fetchData();
    }, []);

    return (
        <section id="_" className="px-16 py-24">
            <DrawerButton />
            <button
                onClick={() => {
                    handleDownload(
                        'https://cms.wicode.io' + agbData?.agbFile?.data?.attributes?.url,
                        "AGB_Wicode.pdf"
                    );
                }}
                className="btn hover:bg-primary absolute top-16 right-16"
            >
                Download
            </button>
            <ReactMarkdown components={customComponents}>
                {agbData?.content}
            </ReactMarkdown>
        </section>
    );
}
