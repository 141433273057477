import Homepage from "../page_home/Homepage";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { API } from "../../App";
import TagManager from "react-gtm-module";

export default function HomePageRoute({ page, loadingFunc }) {
    useEffect(() => {
        window.fbq("track", "Aufruf Homepage");
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageView',
                page: 'Homepage'
            }
        })
    }, []);

    return (
        <section className="router-body">
            <Helmet>
                <title>{page?.metaTitle ?? 'Wicode Homepage'}</title>
                <meta name="og:title" content={page?.metaTitle ?? 'Wicode Homepage'} />
                <meta
                    name="description"
                    content={page?.metaDescription ?? 'Wicode Homepage'}
                />
                <meta
                    name="og:description"
                    content={page?.metaDescription ?? 'Wicode Homepage'}
                />
                <meta name="keywords" content={page?.keywords ?? 'Wicode Homepage'} />
                <link rel="canonical" href={page?.canonicalURL ?? 'https://wicode.io'} />
                <meta
                    name="og:image"
                    content={API + page?.metaImage.data.attributes.url ?? ''}
                />
            </Helmet>
            <Homepage loadingFunc={loadingFunc} />
        </section>
    );
}
