import { Helmet } from "react-helmet";
import SoftwarePage from "../page_development/Development";
import { useEffect } from "react";

export default function SoftwarePageRoute ({ page, loadingFunc }) {
    
    useEffect(() => {
        window.fbq('track', 'Aufruf Softwarepage')
    }, []);
    
    return(
        <section className="router-body">
            <Helmet>
                <title>{page?.metaTitle ?? 'Wicode Software'}</title>
                <link rel='canonical' href={ page?.canonicalURL ?? 'https://wicode.io/software'} />
                <meta name='description' content={page?.metaDescription ?? 'Experten für APIs, Schnittstellen & Cloud-Software! Um Zeit und Geld im Tagesgeschäft zu sparen, ist eine funktionierende Software ein Muss!'} />
                <meta name='keywords' content={page?.keywords ?? 'Cloud, Software, API, Schnittstellen, Cloud-Software, Entwicklung, Cloud-Lösung'} />
            </Helmet>
            <SoftwarePage loadingFunc={loadingFunc}/>
        </section>
    )
}
