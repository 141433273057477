import { useDrawerContext } from "./DrawerContext"


export default function DrawerButton () {

  const { trigger } = useDrawerContext()

    return(
        <div className="fixed top-4 left-4 z-50 cursor-pointer" onClick={trigger}>
          <div className="bg-base-200 border-base-100 border w-12 h-12 flex items-center justify-center rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-base-100"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" /></svg>
          </div>
        </div>
    )
}