import { Helmet } from "react-helmet"
import Agb from "../common/agb"
import { useEffect } from "react";

export default function AgbRoute({ loadingFunc }) {

    useEffect(() => {
        // ReactPixel.init('899428728361041'); 
        // ReactPixel.pageView(); // Für das Tracking von Seitenaufrufen
        window.fbq('track', 'PageView')
    }, []);

    return (
        <section className="router-body">
            <Helmet>
                <title>WICODE - AGBs</title>
                <link rel='canonical' href='https://wicode.io/agb' />
                <meta name='description' content='Allgemeine Geschäftsbedingungen' />
            </Helmet>
            <Agb loadingFunc={loadingFunc}/>
        </section>
    )
}