import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useRef, useEffect, useState } from "react";
import { API } from "../../../App";
import dayjs from "dayjs";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

export default function PostSlider({ posts }) {
    const splideRef = useRef(null);

    const handlePrev = () => {
        if (splideRef.current) {
            splideRef.current.splide.go("-1"); // Go to the previous slide
        }
    };

    const handleNext = () => {
        if (splideRef.current) {
            splideRef.current.splide.go("+1"); // Go to the next slide
        }
    };
    const [windowSize, setWindowSize] = useState(6)

    useEffect(() => {
        // Funktion, die aufgerufen wird, wenn das Fenster resized wird
        function handleResize() {
          if (window.innerWidth <= 824) {
            setWindowSize(1)
          } else if (window.innerWidth <= 1024 ) {
            setWindowSize(2)
          } else {
            setWindowSize(3)
          }
        }
    
        window.addEventListener('resize', handleResize);
        
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
      }, []); 
      

    return (
        <div className="w-full h-fit flex items-center justify-center relative pb-12 lg:pb-24 pt-8">
            <Splide
                options={{
                    perPage: windowSize,
                    perMove: 1,
                    autoplay: false,
                    width: "96%",
                    arrows: false,
                    pagination: windowSize <= 1,
                }}
                ref={splideRef}
            >
                {posts?.slice(1).map((slide, index) => (
                    <SplideSlide key={index}>
                        <div className="flex items-center justify-center">
                            <Sliderpost
                                url={
                                    API + slide?.attributes?.img?.data?.attributes?.url
                                }
                                alt={API + slide.attributes?.img?.data?.attributes?.name}
                                id={slide?.id}
                                title={slide?.attributes?.title}
                                content={slide?.attributes?.content}
                                date={dayjs(slide?.attributes?.publishedAt).format(
                                    "DD.MM.YYYY"
                                )}
                            />
                        </div>
                    </SplideSlide>
                ))}
            </Splide>
        
            { windowSize > 1 && 
            <div className="absolute w-[6.5rem] h-12 bottom-12 pt-8 right-16 flex justify-between">
                <button className="btn btn-circle bg-black/5 border-none hover:bg-black/10">
                    <svg
                        onClick={handlePrev}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1}
                        stroke="currentColor"
                        className="w-8 h-8 text-black"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                    </svg>
                </button>
                <button className="btn btn-circle bg-black/5 border-none hover:bg-black/10">
                    <svg
                        onClick={handleNext}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1}
                        stroke="currentColor"
                        className="w-8 h-8 text-black"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                    </svg>
                </button>
            </div>
            }
        </div>
    );
}

function Sliderpost(props) {
    const navigate = useNavigate()
    const handleClick = (link) => {
        navigate(link)
    }
    return (
        <div className="h-96 mb-6 mt-2 w-[22rem] flex flex-col items-center p-8 shadow-lg bg-white self-center" onClick={() => handleClick('/blog/' + props.id + '/' + props.title + '#_')}>
            <div
                style={{backgroundImage: `url(${props.url})`}}
                className="w-full h-52 mb-4 bg-cover bg-center rounded-sm"
            />
            <div className="flex justify-between w-full items-baseline p-2 md:p-0">
                <h4 className="font-semibold truncate w-64">{props.title}</h4>
                <p className="hidden md:block text-xs">{props.date}</p>
            </div>
            <p className="line-clamp-2 text-sm pt-2 p-2 md:p-0">
                {props.content}
            </p>
            <HashLink to={'/blog/' + props.id + '/' + props.title + '#_'} className='hidden md:block self-end hover:underline text-xs mt-2'>...mehr lesen</HashLink>
            
        </div>
    );
}
