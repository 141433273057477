
import React from 'react'
import { API } from '../../../App'

export default function LogoGrid( logos ) {
    return (
        // <div className='grid grid-cols-3 w-full h-fit gap-4 p-4 md:hidden'>
        //     {logos?.logos?.map((item, index) => (
        //         <img key={index} src={API+item?.attributes?.logo?.data[0]?.attributes?.url} alt="kunde_logo" className='w-full h-fit object-contain rounded-2xl' style={{ aspectRatio: '1.5' }}/>
        //     ))}
        // </div>
        <div className='flex flex-wrap w-full h-fit gap-x-32 gap-y-16 p-4 justify-center max-w-screen-xl absolute top-80'>
            {logos?.logos?.map((item, index) => (
            <div className='flex justify-end items-center h-36 w-36' key={index}>
                <img key={index} src={API+item?.attributes?.logo?.data[0]?.attributes?.url} alt="kunde_logo" className='h-fit opacity-10 w-full max-w-full max-h-40 rounded-3xl' />
            </div>
            ))}
        </div>
    )
}
