import { Helmet } from "react-helmet";
import AboutPage from '../page_about/AboutPage'
import { useEffect } from "react";

export default function AboutPageRoute ({ loadingFunc }) {
    
    useEffect(() => {
        // ReactPixel.init('899428728361041'); 
        // ReactPixel.pageView(); // Für das Tracking von Seitenaufrufen
        window.fbq('track', 'PageView')
    }, []);
    
    return (
        <section className="router-body">
            <Helmet>
                <title>Wicode - Über Uns | Software aus Zwickau & Leipzig</title>
                <link rel='canonical' href='https://wicode.io/unternehmen' />
                <meta name='description' content='Wer wir sind, wo wir herkommen, warum wir da sind und wo wir hin wollen.' />
                <meta name='keywords' content='Über Uns, Philosophie, das Unternehmen, Werte' />
            </Helmet>
            <AboutPage loadingFunc={loadingFunc}/>
        </section>
    );
}
