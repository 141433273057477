import { useEffect, useState } from "react";
import DrawerButton from "../special/drawerButton";
import axios from "axios";
import { API } from "../../App";
import ReactMarkdown from "react-markdown";
import { customComponents } from "./markdownCustom";

export default function Datenschutz({ loadingFunc }) {
    
    const [datenschutz, setdatenschutz] = useState()
    useEffect(() => {   
        const fetchdata = async () => {
            await axios.get(API + '/api/datenschutz')
            .then(res => setdatenschutz(res.data.data.attributes.content))
            .catch(err => console.log(err))

            loadingFunc()
        }

        fetchdata()
    }, [])
    
    return(
        <section id="_" className="px-16 py-24">
            <DrawerButton />
            <h1 className="text-2xl font-semibold underline">Datenschutzerklärung</h1>
            <div className="px-2 pt-2">
                <ReactMarkdown components={customComponents}>
                    {datenschutz}
                </ReactMarkdown>

            </div>
        </section>
    )
}
